import React from 'react'
import { Dialog, DialogTitle, DialogContent, makeStyles, Typography, DialogActions } from '@material-ui/core';
import {Button} from '@material-ui/core';
import CloseIcon from '@material-ui/icons/Close';
import global from './../components/Global';

const useStyles = makeStyles(theme => ({
    dialogWrapper: {
        padding: theme.spacing(2),
        position: 'absolute',
        top: theme.spacing(5)
    },
    dialogTitle: {
        paddingRight: '0px'
    }
}))

export default function Modal_Frame(props) {

    var { title, children, maxWidth, isOpen, onClose, Actions } = props;
    const classes = useStyles();
    return (
        <Dialog open={isOpen} maxWidth={maxWidth} fullWidth >
            <DialogTitle className={classes.dialogTitle} style={{ backgroundColor:global.COLOR.Secondary,height:"16%"}}>
                <div style={{ display: 'flex' }}>
                    <Typography variant="h4" component="div" style={{ flexGrow: 1, color:'white', fontSize:25 }}>
                       {title}
                    </Typography>
                    <Button
                        style={{color:'white',paddingTop:1}}
                        onClick={()=>{onClose();}}>
                        <CloseIcon />
                    </Button>
                </div>
            </DialogTitle>
            <DialogContent dividers>
                {children}
            </DialogContent>
            <DialogActions>
                {Actions}
            </DialogActions>
        </Dialog>
    )
}