import { Route, Redirect } from 'react-router-dom';
import Session from "react-session-api";


export { PrivateRoute };

function PrivateRoute({ component: Component, ...rest }) {
    return (
        <Route {...rest} render={props => {
            if (Session.get("UserID") !== undefined &&
                Session.get("UserID") !== "" &&
                Session.get("UserID") !== null) {
                // authorized so return component
                return <Component {...props} />
            }

            // not logged in so redirect to login page with the return url
            return <Redirect to={{ pathname: '/login', state: { from: props.location } }} />

        }} />
    );
}