import React from "react";
import { Button as MuiButton, makeStyles } from "@material-ui/core";
import global from "./../Global";

const useStyles = makeStyles((theme) => ({
  root: {
    margin: theme.spacing(1),
    marginTop: theme.spacing(2),
    width: "16ch",
    height: "34px",
    backgroundColor: global.COLOR.Secondary,
    "&:hover": {
      backgroundColor: global.COLOR.Primary,
    },
  },

  label: {
    textTransform: "none",
    color: "white",
  },
}));

export default function Button(props) {
  const { text, size, color, variant, onClick, ...other } = props;
  const classes = useStyles();

  return (
    <MuiButton
      variant={variant || "contained"}
      margin="normal"
      size={size || "small"}
      onClick={onClick}
      {...other}
      classes={{ root: classes.root, label: classes.label }}
    >
      {text}
    </MuiButton>
  );
}
