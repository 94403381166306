import React from 'react';
import Grid from '@material-ui/core/Grid';
//import { createMuiTheme } from '@material-ui/core/styles';
import { DataGrid } from '@material-ui/data-grid';
import { withStyles } from '@material-ui/styles';
import SaveIcon from '@material-ui/icons/Save';
import { Formik, Form } from 'formik';
import global from './../Global';
import BiztranContorls_01 from '../../BiztranControls/BiztranControls';
import axios from 'axios';

let UserID = [];

/* const theme = createMuiTheme({
    typography: {
      fontSize: 13,
    },
}); */

const styles = theme => ({
    root: {
        '& .super-app-theme--header': {
            backgroundColor: global.COLOR.Secondary,
            color: 'white',
            fontSize: 16,
        },
    },
});

const columns = [
    /* { field: 'id', headerName: '.', headerClassName: 'super-app-theme--header', headerAlign: 'center', width: 34 }, */
    { field: 'user_type', headerName: 'User Type', headerClassName: 'super-app-theme--header', headerAlign: 'center', width: 250 },
    { field: 'user_name', headerName: 'User Name', headerClassName: 'super-app-theme--header', headerAlign: 'center', width: 250 },
];

class Add_Network_Multi_Users extends React.Component {

    constructor(props) {
        super(props);
        this.PopulateData = this.PopulateData.bind(this);
        this.AddUsers = this.AddUsers.bind(this);
        this.state = {
            UsersData: [], rows: [], ProjectID: '', SelectedRows: []
        };
    }

    PopulateData() {

        try {
            var NetworkID = this.props.NetworkID;
            //var UserID = localStorage.getItem("UserID");
            var bodyFormData = new FormData();
            bodyFormData.append('NetworkID', NetworkID);
            axios({
                url: global.BASE_URL + "STLCNPTNetwork/AddNetworkUserList",
                method: 'post',
                headers: { 'Content-Type': 'multipart/form-data' },
                data: bodyFormData
            })
                .then((response) => {

                    if (response.data.Response === false) {
                        this.setState({ rows: [] });
                    }
                    else if (response.data.length > 0) {
                        const selectedValues = response.data.filter(r => r.isexistingnetworkuser === 1)
                            .map(filteredrows => (filteredrows.id));
                        this.setState({ rows: response.data, SelectedRows: selectedValues });
                    }
                })
                .catch((response) => {
                    console.log(response);
                });
        } catch (error) {
            console.log(error);
        }
    }

    AddUsers() {
        try {

            if (UserID.length > 0) {
                var bodyFormData = new FormData();
                var NetworkID = this.props.NetworkID;
                for (var i = 0; i < UserID.length; i++) {
                    bodyFormData.append('UserID[]', UserID[i]);
                }
                bodyFormData.append('NetworkID', NetworkID);
                bodyFormData.append('Group', this.props.Group);

                axios({
                    url: global.BASE_URL + "STLCNPTNetwork/AddUser",
                    method: 'post',
                    headers: { 'Content-Type': 'multipart/form-data' },
                    data: bodyFormData
                })
                    .then((response) => {
                        if (response.data.Response === true) {
                            alert("Saved Successfully.");
                        }
                        else {
                            alert("Save Failed.");
                        }
                    })
                    .catch((response) => {
                        console.log(response);
                    });
            }
            else {
                alert("Save Failed.");
            }
        } catch (error) {
            console.log(error);
        }
    }

    currentlySelected(selections) {

        UserID = selections.rowIds;
    }

    PopulateUserData() {
        try {
            var bodyFormData = new FormData();
            var NetworkID = this.props.NetworkID;
            bodyFormData.append('NetworkID', NetworkID);
            axios({
                url: global.BASE_URL + "STLCNPTNetwork/UserList",
                method: 'post',
                headers: { 'Content-Type': 'multipart/form-data' },
                data: bodyFormData
            })
                .then((response) => {

                    this.setState({ Users: response.data });
                })
                .catch((response) => {
                    console.log(response);
                });
        }
        catch (error) {
            console.log(error);
        }
    }

    componentDidMount() {

        if (this.props.NetworkID !== "") {
            //this.setState({ProjectID:this.props.ProjectIBDID});
            this.state.ProjectID = this.props.ProjectIBDID;
            UserID = [];
            this.PopulateData();
            this.PopulateUserData();
        }
    }

    render() {
        const { classes } = this.props;
        return (
            <div>
                <Formik enableReinitialize>
                    {formik => {
                        return <Form>
                            <Grid container spacing={2}>
                                <Grid item xs={12}>
                                    <BiztranContorls_01.Button style={{ color: 'white', backgroundColor: global.COLOR.Secondary, marginTop: '10px' }} startIcon={<SaveIcon />} onClick={this.AddUsers}
                                        text="Add Users"
                                    />
                                    <div style={{ width: '100%', height: "250px", marginTop: 3 }} className={classes.root} >
                                        <DataGrid
                                            rows={this.state.rows}
                                            columns={columns}
                                            pageSize={25}
                                            rowHeight={22}
                                            headerHeight={34}
                                            checkboxSelection
                                            hideFooterPagination
                                            selectionModel={this.state.SelectedRows}
                                            onSelectionModelChange={(newSelection) => {
                                                //console.log(newSelection);
                                                UserID = newSelection.selectionModel;
                                            }}
                                        />
                                    </div>
                                </Grid>
                            </Grid>
                        </Form>
                    }
                    }
                </Formik>
            </div>
        );
    }
}

export default withStyles(styles)(Add_Network_Multi_Users);
