import React from 'react';
import Grid from '@material-ui/core/Grid';
import {Formik, Form} from 'formik';
import * as Yup from 'yup';
import axios from 'axios';
import { withRouter } from 'react-router-dom';
import AddCircleOutlineIcon from '@material-ui/icons/AddCircleOutline';
import SaveIcon from '@material-ui/icons/Save';
import BiztranContorls_01 from '../../BiztranControls/BiztranControls';
import BiztranControl from "../../BiztranControls_01/BiztranControls";
import Modal_Frame from '../../BiztranControls/Modal_Frame';
import global from './../Global';
import Add_Question from './Add_Question';
import AddQuestion from "./AddQuestion";

let SurveyTempID = '', SurveyTempDtlsID = '', btnDeleteDisable = true, btnDeleteColor = '#dddddd';
let btnDisable = null, btnColor = null,TemplateName = '';

const validationSchema = Yup.object({
    tempname:Yup.string().required('Required!'),
    layertype:Yup.string().required('Required!'),
    surveytype:Yup.string().required('Required!'),
    DataCaptureMode:Yup.string().required('Required!'),
    FrequencyInSecs:Yup.string().ensure().when('DataCaptureMode', {
        is:(DataCaptureMode) => DataCaptureMode === 'Auto' || 'Both',
        then: Yup.string().required('Required!')
      }),
    AccuracyInCm:Yup.string().required('Required!'),
    description:Yup.string().required('Required!')
});

const onSubmit = values => {
    try {
        if(values.tempname !== ""){
            if(values.FrequencyInSecs === ""){
                values.FrequencyInSecs = "0";
            }
            var bodyFormData = new FormData();
            bodyFormData.append('SurveyTempID',SurveyTempID);
            bodyFormData.append('TempName',values.tempname);
            bodyFormData.append('SurveyType',values.surveytype);
            bodyFormData.append('TempType',values.layertype);
            bodyFormData.append('DataCaptureMode',values.DataCaptureMode);
            bodyFormData.append('FrequencyInSecs',values.FrequencyInSecs);
            bodyFormData.append('AccuracyInCm',values.AccuracyInCm);
            bodyFormData.append('Description',values.description);
            axios({
                url: global.BASE_URL+"SurveyAPI/InsertSurveyTemplates",
                method: 'post',
                headers: {'Content-Type': 'multipart/form-data' },
                data: bodyFormData
            })
            .then((response) => {
                if(response.data.Response === true){
                    alert("Saved Successfully.");
                    btnDisable = false;
                    btnColor = global.COLOR.Secondary;
                    SurveyTempID = response.data.Message;
                }
                else{
                    alert("Save Failed.");
                }
            })
            .catch((response) => {
                console.log(response);
            });
        }
        else{
            alert("Template Name Required.")
        }
    } catch (error) {
        console.log(error);
    }
}

class Create_Survey_Temp extends React.Component {
    constructor(props){
        super(props);
        this.PopulateData = this.PopulateData.bind(this);
        this.openModal = this.openModal.bind(this);
        this.openAddModal = this.openAddModal.bind(this);
        //this.SaveSurveyTempData = this.SaveSurveyTempData.bind(this);
        this.handleChange = this.handleChange.bind(this);
        this.state ={
          rows:[], columns:[], isPopOpen:false, TemplateName:'', TempName:'', btnDisable:null,btnColor:null, SurveyTemplateID:'', SurveyType:'', LayerType:'',
          tempname:'',layertype:'',surveytype:'',DataCaptureMode:'',FrequencyInSecs:'',AccuracyInCm:'', description:'', DisableFrInSec:true, DisableAccInCm:true, StyleData:[]
        };
    }

    openModal(row){
        SurveyTempDtlsID = row;
        btnDeleteDisable = false;
        btnDeleteColor = 'red';
        this.setState({isPopOpen:true});
    }

    openAddModal(){
        SurveyTempDtlsID = "";
        btnDeleteDisable = true;
        btnDeleteColor = '#dddddd';
        this.setState({isPopOpen:true});
    }

    FillStyleData(){
        try {
            var bodyFormData = new FormData();
            bodyFormData.append('SurveyTempID',SurveyTempID);
            axios({
                url: global.BASE_URL+"SurveyAPI/SurveyMapStyleData",
                method: 'post',
                headers: {'Content-Type': 'multipart/form-data' },
                data: bodyFormData
            })
            .then((response) => {
                try {
                    if(response.data.Response === false){
                        console.log("Failed to fetch data")
                        this.setState({
                            StyleData:[]
                        });
                    }
                    else{
                        this.setState({
                            StyleData:response.data.QuestionsDtls
                        });
                    }
                } catch (error) {
                    console.log(error);
                }
            })
            .catch((response) => {
                console.log(response);
            });
        } catch (error) {
            console.log(error);
        }
    }

    /* handleClose (){
        this.setState({isPopOpen:false});
        //this.PopulateData();
    } */

    handleClose=()=>{
        this.setState({isPopOpen:false});
    }

    SurveyTypeData() {
        let SurveyType = [
            { key: 'Pre Planning', value:'Pre Planning' },
            { key: 'After Planning', value:'After Planning' },
            { key: 'Post Execution', value:'Post Execution' }
        ]
        let SurveyTypeCombo = SurveyType.map((data) =>
            <option
                key={data.key}
                value={data.value}
            >
                {data.key}
            </option>
        );
        return SurveyTypeCombo;
    }

    LayerTypeData() {
        let LayerType = [
            { key: 'Point', value:'Point' },
            { key: 'Line', value:'Line' },
            { key: 'Polygon', value:'Polygon' }
        ]
        let LayerTypeCombo = LayerType.map((data) =>
            <option
                key={data.key}
                value={data.value}
            >
                {data.key}
            </option>
        );
        return LayerTypeCombo;
    }

    CaptureModeData() {
        let CaptureMode = [
            { key: 'Auto', value:'Auto' },
            { key: 'Manual', value:'Manual' },
            { key: 'Both', value:'Both' }
        ]
        let CaptureModeCombo = CaptureMode.map((data) =>
            <option
                key={data.key}
                value={data.value}
            >
                {data.key}
            </option>
        );
        return CaptureModeCombo;
    }

    PopulateData(){
        try {
            var bodyFormData = new FormData();
            bodyFormData.append('SurveyTempID',SurveyTempID);
            axios({
                url: global.BASE_URL+"SurveyAPI/GetSurveyTempDetails",
                method: 'post',
                headers: {'Content-Type': 'multipart/form-data' },
                data: bodyFormData
            })
            .then((response) => {
                try {
                    if(response.data.Response === false){
                        console.log("Failed to fetch data")
                    }
                    else{
                        if(response.data.TemplateDtls[0].data_capture_mode === "Manual"){
                            this.setState({ DisableFrInSec:true, DisableAccInCm:true });
                        }
                        else{
                            this.setState({ DisableFrInSec:false, DisableAccInCm:false });
                        }
                        this.setState({
                            rows:response.data.QuestionsDtls, 
                            TemplateName:response.data.TemplateDtls[0].template_name, 
                            SurveyType:response.data.TemplateDtls[0].survey_type,
                            tempname:response.data.TemplateDtls[0].template_name,
                            layertype:response.data.TemplateDtls[0].template_type,
                            surveytype:response.data.TemplateDtls[0].survey_type,
                            DataCaptureMode:response.data.TemplateDtls[0].data_capture_mode,
                            FrequencyInSecs:response.data.TemplateDtls[0].frequency_in_secs,
                            AccuracyInCm:response.data.TemplateDtls[0].accuracy_in_cm,
                            description:response.data.TemplateDtls[0].description
                        });
                        TemplateName = response.data[0].template_name;
                    }
                } catch (error) {
                    console.log(error);
                }
            })
            .catch((response) => {
                console.log(response);
            });
        } catch (error) {
            console.log(error);
        }
    }

    handleChange(event){
        if(event.target.value === "Auto"){
            this.setState({ DisableFrInSec:false, DisableAccInCm:false, DataCaptureMode:event.target.value });
        }
        else if(event.target.value === "Both"){
            this.setState({ DisableFrInSec:false, DisableAccInCm:false, DataCaptureMode:event.target.value });
        }
        else{
            this.setState({ DisableFrInSec:true, DisableAccInCm:true, DataCaptureMode:event.target.value });
        }
    };

    componentDidMount(){
        if(this.props.location.state.SurveyTempID !== ""){     
            SurveyTempID = this.props.location.state.SurveyTempID;
            this.PopulateData();
            btnDisable = false;
            btnColor = global.COLOR.Secondary;
        }
        else{
            SurveyTempID = "";
            btnDisable = true;
            btnColor = '#dddddd';
        }
        this.state.columns = [
            { field: 'id', headerName: '.', renderCell: (params) => (<strong> <a href="javascript:;" onClick={() => { this.openModal(params.id); }} >Edit</a> </strong> ), headerClassName: 'super-app-theme--header', headerAlign: 'center', width: 70 },
            { field: 'seq', headerName: 'Seq', headerClassName: 'super-app-theme--header', headerAlign: 'center', width: 90 },
            { field: 'question_type', headerName: 'Question Type', headerClassName: 'super-app-theme--header', headerAlign: 'center', width: 180 },
            { field: 'question_description', headerName: 'Question Description', headerClassName: 'super-app-theme--header', headerAlign: 'center', width: 250 },
            { field: 'guidance_hint', headerName: 'Guidance Hint', headerClassName: 'super-app-theme--header', headerAlign: 'center', width: 240 },
            { field: 'choice_options', headerName: 'Choice Options', headerClassName: 'super-app-theme--header', headerAlign: 'center', width: 250 },
            { field: 'is_mandatory', headerName: 'Is Mandatory', headerClassName: 'super-app-theme--header', headerAlign: 'center', width: 140 },
        ];
    }

    render(){
        let SurveyTypeCombo = this.SurveyTypeData();
        let LayerTypeCombo = this.LayerTypeData();
        let CaptureModeCombo = this.CaptureModeData();
        return(
            <div>
                <Formik initialValues = {{ tempname:this.state.tempname, layertype:this.state.layertype, surveytype:this.state.surveytype, DataCaptureMode:this.state.DataCaptureMode, FrequencyInSecs:this.state.FrequencyInSecs, AccuracyInCm:this.state.AccuracyInCm, description:this.state.description }}
                validationSchema = {validationSchema} onSubmit={onSubmit} enableReinitialize>
                    {formik => {
                        return <Form>
                            <Grid container spacing={1} style={{width:"70%"}}>
                                <Grid item xs={6}>
                                    <BiztranControl control='input' type='text' label='Template Name' name='tempname' style={{ height:'30px', width:'100%' }} />
                                </Grid>
                                <Grid item xs={6}>
                                    <BiztranControl control='select' label='Survey Type' name='surveytype' options={SurveyTypeCombo} onChange={event => this.setState({surveytype:event.target.value})} style={{ height:'30px', width:'100%' }} />
                                </Grid>
                                <Grid item xs={6}>
                                    <BiztranControl control='select' label='Layer Type' name='layertype' options={LayerTypeCombo} onChange={event => this.setState({layertype:event.target.value})} style={{ height:'30px', width:'100%' }} />
                                </Grid>
                                <Grid item xs={6}>
                                    <BiztranControl control='select' label='Data Capture Mode' name='DataCaptureMode' options={CaptureModeCombo}  onChange={this.handleChange} style={{ height:'30px', width:'100%' }} />
                                </Grid>
                                <Grid item xs={6}>
                                    <BiztranControl control='input' type='text' label='Frequency In Secs' name='FrequencyInSecs' disabled={this.state.DisableFrInSec} style={{ height:'30px', width:'100%' }} />
                                </Grid>
                                <Grid item xs={6}>
                                    <BiztranControl control='input' type='text' label='Accuracy In Cm' name='AccuracyInCm' disabled={this.state.DisableAccInCm} style={{ height:'30px', width:'100%' }} />
                                </Grid>
                                <Grid item xs={12}>
                                    <BiztranControl control='textarea' type='text' label='Description' name='description' style={{ height:'40px', width:'100%' }} />
                                </Grid>
                                <Grid item xs={12}>
                                    <BiztranContorls_01.Button type="submit" startIcon={<SaveIcon />} backcolor={global.COLOR.Secondary}
                                        text="Save" /* onClick={this.SaveSurveyTempData} */
                                    /> 
                                    <BiztranContorls_01.Button text="Add Question" disabled={btnDisable} backcolor={btnColor} startIcon={<AddCircleOutlineIcon/>} onClick={() => { this.openAddModal(); }} />                       
                                </Grid>
                            </Grid>
                            <Modal_Frame title="Add Question" maxWidth="sm" isOpen={this.state.isPopOpen} onClose={this.handleClose}>
                                <AddQuestion SurveyTempDtlsID={SurveyTempDtlsID} SurveyTempID={SurveyTempID} handleClose={this.handleClose} PopulateData={this.PopulateData} btnDeleteDisable={btnDeleteDisable} btnDeleteColor={btnDeleteColor} /> 
                            </Modal_Frame> 
                            <BiztranContorls_01.DataGrid rows={this.state.rows} columns={this.state.columns} rowHeight={25} checkboxSelection={false} />
                        </Form>
                    }}
                </Formik>
            </div>
        )
    }
}

export default withRouter(Create_Survey_Temp);
