import React from 'react';
import {Formik, Form} from 'formik';
import axios from 'axios';
import SearchIcon from '@material-ui/icons/Search';
import BiztranControls from './../BiztranControls/BiztranControls';
import Modal_Frame from './../BiztranControls/Modal_Frame';
import 'moment/locale/it.js';
import 'rc-datepicker/lib/style.css';
import { DatePickerInput } from 'rc-datepicker';
import global from './Global';
import Error_Page_Edit from "./Error_Page_Edit";


let ErrorID='';
let IsFixed = '';
const IsFixedOptions =[
    { id:1, title:'Fixed' },
    { id:0, title:'Not Fixed' }
];

export default class Error_Page extends React.Component {
    constructor(props) {
        super(props);
        this.PopulateData = this.PopulateData.bind(this);
        this.openModal = this.openModal.bind(this);
        this.onClear = this.onClear.bind(this);
        this.onClear2 = this.onClear2.bind(this);
        this.onClear3 = this.onClear3.bind(this);
        this.onClear4 =this.onClear4.bind(this);
        var fromDate = parseInt(new Date().getMonth()) +"/"+ new Date().getDate() + "/"+ new Date().getFullYear();
        var toDate = parseInt(new Date().getMonth()+1) +"/"+ new Date().getDate() + "/"+ new Date().getFullYear();
        var fixfromdate = parseInt(new Date().getMonth()) +"/"+ new Date().getDate() + "/"+ new Date().getFullYear();
        var fixtodate =  parseInt(new Date().getMonth()+1) +"/"+ new Date().getDate() + "/"+ new Date().getFullYear();
        var defaultfromDate = (new Date()).setDate((new Date()).getMonth() - 1);
        var defaulttoDate = new Date();
        var defaultfixedfromDate = (new Date()).setDate((new Date()).getMonth() - 1);
        var defaultfixedtoDate = new Date();
        this.state ={
          rows:[], columns:[], isPopOpen:false, datePickerInputDate:fromDate,datePickerInputDate2:toDate,datePickerInputDate3:fixfromdate,datePickerInputDate4:fixtodate,defaultfromDate:defaultfromDate,defaulttoDate:defaulttoDate,defaultfixedfromDate:defaultfixedfromDate,defaultfixedtoDate:defaultfixedtoDate
        };
    }

    openModal(row){
        ErrorID = row;
        this.setState({isPopOpen:true});
        
    }
    handleChangeIsFixed = (event) => {
        IsFixed = event.target.value
    };

    handleClose = ()=>{
        this.setState({isPopOpen:false});
    }

    PopulateData(){
        var ProjectGroup = document.getElementById("projectgroup").value;
        var UserName = document.getElementById("username").value;
        var ErrorText = document.getElementById("errortext").value;
        var SearchByAny = document.getElementById("searchbyany").value;
        var bodyFormData = new FormData();
        bodyFormData.append('ErrorFromDate',this.state.datePickerInputDate);
        bodyFormData.append('ErrorToDate',this.state.datePickerInputDate2);
        bodyFormData.append('FixFromDate',this.state.datePickerInputDate3);
        bodyFormData.append('FixToDate',this.state.datePickerInputDate4);
        bodyFormData.append('ProjectGroup',ProjectGroup);
        bodyFormData.append('UserName',UserName);
        bodyFormData.append('ErrorText',ErrorText);
        bodyFormData.append('SearchString',SearchByAny );
        bodyFormData.append('IsFixed',IsFixed );
        axios({
            url: global.BASE_URL+"STLAPI/ErrorList",
            method: 'post',
            headers: {'Content-Type': 'multipart/form-data' },
            data: bodyFormData
        })
        .then((response) => {
            this.setState({rows:response.data.dtData});
        })
        .catch((response) => {
            console.log(response);
        });
    }

    onClear(){
        this.setState({datePickerInputDate:'',defaultfromDate:''});
    }

    onClear2(){
        this.setState({datePickerInputDate2:'',defaulttoDate:''});
    }
    onClear3(){
        this.setState({datePickerInputDate3:'',defaultfixedfromDate:''});
    }
    onClear4(){
        this.setState({datePickerInputDate4:'',defaultfixedtoDate:''});   
    }

    componentDidMount(){
        this.PopulateData();
        this.state.columns = [
            { field: 'id', headerName: '.', renderCell: (params) => (<strong> <a href="javascript:;" onClick={() => { this.openModal(params.id); }} >View</a> </strong> ), headerClassName: 'super-app-theme--header', headerAlign: 'center', width: 80 },
            { field: 'error_date', headerName: 'Error Date', headerClassName: 'super-app-theme--header', headerAlign: 'center', width: 160 },
            { field: 'project_group', headerName: 'Project Group', headerClassName: 'super-app-theme--header', headerAlign: 'center', width: 160 },
            { field: 'app_version', headerName: 'App Version', headerClassName: 'super-app-theme--header', headerAlign: 'center', width: 160 },
            { field: 'user_name', headerName: 'User Name', headerClassName: 'super-app-theme--header', headerAlign: 'center', width: 180 },
            { field: 'module_name', headerName: 'Module Name', headerClassName: 'super-app-theme--header', headerAlign: 'center', width: 210 },
            { field: 'method_name', headerName: 'Method Name', headerClassName: 'super-app-theme--header', headerAlign: 'center', width: 210 },
            { field: 'error_msg', headerName: 'Error Message', headerClassName: 'super-app-theme--header', headerAlign: 'center', width: 250 },
            { field: 'error_msg_details', headerName: 'Error Message Details', headerClassName: 'super-app-theme--header', headerAlign: 'center', width: 250 },
            { field: 'is_fixed', headerName: 'Is Fixed', headerClassName: 'super-app-theme--header', headerAlign: 'center', width: 160 },
            { field: 'fixed_by', headerName: 'Fixed By', headerClassName: 'super-app-theme--header', headerAlign: 'center', width: 160 },
            { field: 'fixed_date', headerName: 'Fixed On', headerClassName: 'super-app-theme--header', headerAlign: 'center', width: 160 },
            { field: 'fix_remarks', headerName: 'Fixed Remarks', headerClassName: 'super-app-theme--header', headerAlign: 'center', width: 150 },
        ];
    }

    render(){
        return(
            <div>
                <Formik>
                    {formik => {
                        return <Form>
                            <div>
                            <table>
                                <tr>
                                <td>
                                    <div style={{width:"223px",marginLeft:6}}>
                                    <label style={{color:"grey",fontSize:12}}>From Date</label>
                                    <DatePickerInput onChange={(jsDate, dateString) => this.setState({datePickerInputDate:dateString}) } locale="en" displayFormat="DD/MMM/YYYY"
                                        className='my-react-component' onClear={this.onClear} showOnInputClick maxDate={new Date()} returnFormat="DD/MMM/YYYY"  defaultValue={this.state.defaultfromDate}
                                    />
                                    </div>
                                </td>
                                <td>
                                    <div style={{width:"223px",marginLeft:12}}>
                                    <label style={{color:"grey",fontSize:12}}>To Date</label>
                                    <DatePickerInput onChange={(jsDate, dateString) => this.setState({datePickerInputDate2:dateString}) } locale="en" displayFormat="DD/MMM/YYYY"
                                        className='my-react-component' onClear={this.onClear2} showOnInputClick maxDate={new Date()} returnFormat="DD/MMM/YYYY" defaultValue={this.state.defaulttoDate}             
                                    />
                                    </div>
                                </td>
                                <td>
                                    <div style={{width:"223px",marginLeft:16}}>
                                    <label style={{color:"grey",fontSize:12}}>Fixed From Date</label>
                                    <DatePickerInput onChange={(jsDate, dateString) => this.setState({datePickerInputDate3:dateString}) } locale="en" displayFormat="DD/MMM/YYYY"
                                        className='my-react-component' onClear={this.onClear3} showOnInputClick maxDate={new Date()} returnFormat="DD/MMM/YYYY"  defaultValue={this.state.defaultfixedfromDate}
                                    />
                                    </div>
                                </td>
                                <td>
                                    <div style={{width:"223px",marginLeft:12}}>
                                    <label style={{color:"grey",fontSize:12}}>Fixed To Date</label>
                                    <DatePickerInput onChange={(jsDate, dateString) => this.setState({datePickerInputDate4:dateString}) } locale="en" displayFormat="DD/MMM/YYYY"
                                        className='my-react-component' onClear={this.onClear4} showOnInputClick maxDate={new Date()} returnFormat="DD/MMM/YYYY" defaultValue={this.state.defaultfixedtoDate}             

                                    />
                                 </div>
                                
                                </td>
                                <td>
                                <div style={{marginTop:15}}>
                                <BiztranControls.Select label="IsFixed" name="isfixed" onChange={this.handleChangeIsFixed} options={IsFixedOptions} />
                                </div>
                                </td>
                                </tr>
                                </table>
                            </div>
                            <BiztranControls.Input style={{marginTop:16}} label="Project Group" name="ProjectGroup" id="projectgroup" />
                            <BiztranControls.Input style={{marginTop:16}} label="User Name" name="UserName" id="username" />
                            <BiztranControls.Input style={{marginTop:16}} label="Error Text" name="ErrorText" id="errortext" />
                            <BiztranControls.Input style={{marginTop:16}} label="Search By Any" name="SearchByAny" id="searchbyany" />
                           
                            <BiztranControls.Button backcolor={global.COLOR.Secondary} text="POPULATE" startIcon={<SearchIcon/>} onClick={() => { this.PopulateData(); }} />
                            <Modal_Frame title="Error Tracker Details" maxWidth="md" isOpen={this.state.isPopOpen} onClose={this.handleClose}>
                                <Error_Page_Edit ErrorID={ErrorID} />
                            </Modal_Frame> 
                            <BiztranControls.DataGrid rows={this.state.rows} columns={this.state.columns} rowHeight={22}/>
                        </Form>
                    }}
                </Formik>
            </div>
        );
    }
}
