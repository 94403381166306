import { Formik, Form } from 'formik';
import React, { Component } from 'react';
import axios from 'axios';
import BiztranControls from '../../BiztranControls/BiztranControls';
import { Redirect } from 'react-router-dom';
import global from '../Global';
import 'rc-datepicker/lib/style.css';
import 'moment/locale/en-gb.js';
import { DatePickerInput } from 'rc-datepicker';
import SearchIcon from '@material-ui/icons/Search';
import { GridToolbarContainer, GridToolbarExport } from "@mui/x-data-grid";
import Moment from "moment";

var date = new Date();

Date.prototype.addDays = function (days) {
  var date = new Date(this.valueOf());
  date.setDate(date.getDate() + days);
  return date;
}

let Group = 'Transport';

const GroupOptions = [
  { id: 'Transport', title: 'Transport' },
  { id: 'FTTH', title: 'FTTH' }
];



export default class APIConsumption extends React.Component {
  constructor(props) {
    super(props);
    var fromDate = parseInt(new Date().getMonth()) + "/" + new Date().getDate() + "/" + new Date().getFullYear();
    var toDate = parseInt(new Date().getMonth() + 1) + "/" + new Date().getDate() + "/" + new Date().getFullYear();
    var defaultfromDate = Moment(new Date().addDays(-30)).format("DD/MMM/YYYY");
    var defaulttoDate = Moment(new Date()).format("DD/MMM/YYYY");
    var HitCountFTTH = '';
    var HitCountT = '';
    var CostFtth = '';
    var CostT = '';
    var TotalHit = '';
    var TotalCost = '';
    this.state = {
      rows: [], columns: [], FromDate: defaultfromDate, ToDate: toDate, defaultfromDate: defaultfromDate,
      defaulttoDate: defaulttoDate, HitCountFTTH: 0, HitCountT: 0, CostFtth: 0, CostT: 0,
      TotalHit: TotalHit, TotalCost: TotalCost
    };

  }

  handleChangeGroup = (event) => {
    Group = event.target.value
  };

  PopulateData() {
    try {
      var HitCountFTTH = 0;
      var HitCountTrans = 0;
      var HitCostFTTH = 0;
      var HitCostTrans = 0;
      var TotalHitCount = 0;
      var TotalHitCost = 0;
      var bodyFormData = new FormData();
      var ProjectName = document.getElementById("projectname").value;
      var NetworkName = document.getElementById("networkname").value;
      var UserName = document.getElementById("username").value;
      bodyFormData.append('Group', Group);
      bodyFormData.append('ProjectName', ProjectName);
      bodyFormData.append('NetworkName', NetworkName);
      bodyFormData.append('UserName', UserName);
      bodyFormData.append('FromDate', this.state.FromDate);
      bodyFormData.append('ToDate', this.state.ToDate);

      axios({
        url: global.BASE_URL + "STLCNPTNetwork/APIConsumptionReport",
        method: 'post',
        headers: { 'Content-Type': 'multipart/form-data' },
        data: bodyFormData
      })
        .then((response) => {
          this.setState({ rows: response.data });
          for (var i = 0; i < response.data.length; i++) {
            if (response.data[i].project_group === "Transport") {
              HitCountTrans = HitCountTrans + response.data[i].total_hit_count;
              HitCostTrans = HitCostTrans + response.data[i].hit_cost_inr77;
            } else if (response.data[i].project_group === "FTTH") {
              HitCountFTTH = HitCountFTTH + response.data[i].total_hit_count;
              HitCostFTTH = HitCostFTTH + response.data[i].hit_cost_inr77;
            }
          }
          this.setState({ HitCountT: HitCountTrans });
          this.setState({ CostT: HitCostTrans });
          this.setState({ HitCountFTTH: HitCountFTTH });
          this.setState({ CostFtth: HitCostFTTH });
          this.setState({ TotalHit: HitCountTrans + HitCountFTTH });
          this.setState({ TotalCost: HitCostTrans + HitCostFTTH });
        })
        .catch((response) => {
          console.log(response);
        });

      //this.CalculateApiHit();
    } catch (error) {
      console.log(error);
    }
  }

  CalculateApiHit() {
    this.setState({ HitCountT: 0 });
    this.setState({ CostT: 0 });
    this.setState({ HitCountFTTH: 0 });
    this.setState({ CostFtth: 0 });
    var TotalHitCount = 0;
    var TotalHitCost = 0;
    //this.setState({ TotalHit: this.state.HitCountT + this.state.HitCountFTTH });
    //this.setState({ TotalCost: this.state.CostT + this.state.CostFtth });
    debugger;
    try {
      var bodyFormData = new FormData();
      var ProjectName = document.getElementById("projectname").value;
      var NetworkName = document.getElementById("networkname").value;
      var UserName = document.getElementById("username").value;
      bodyFormData.append('Group', Group);
      bodyFormData.append('ProjectName', ProjectName);
      bodyFormData.append('NetworkName', NetworkName);
      bodyFormData.append('UserName', UserName);
      bodyFormData.append('FromDate', this.state.FromDate);
      bodyFormData.append('ToDate', this.state.ToDate);
      axios({
        url: global.BASE_URL + "STLCNPTNetwork/APIConsumptionReport",
        method: 'post',
        headers: { 'Content-Type': 'multipart/form-data' },
        data: bodyFormData
      })
        .then((response) => {
          for (var i = 0; i < response.data.length; i++) {
            TotalHitCount = TotalHitCount + response.data[i].total_hit_count;
            TotalHitCost = TotalHitCost + response.data[i].hit_cost_inr77;
          }
          console.log(TotalHitCount);
          // this.setState({ HitCountT: response.data.TotalApiHit[0].total_hit_count });
          // this.setState({ CostT: response.data.TotalApiHit[0].hit_cost_inr77 });
          // this.setState({ HitCountFTTH: response.data.TotalApiHit[1].total_hit_count });
          // this.setState({ CostFtth: response.data.TotalApiHit[1].hit_cost_inr77 });
          // this.setState({ TotalHit: response.data.TotalApiHit[0].total_hit_count + response.data.TotalApiHit[1].total_hit_count });
          this.setState({ TotalHit: TotalHitCount });
          this.setState({ TotalCost: TotalHitCost });
          // this.setState({ TotalCost: response.data.TotalApiHit[0].hit_cost_inr77 + response.data.TotalApiHit[1].hit_cost_inr77 });


        })
        .catch((response) => {
          console.log(response);
        });
      //this.setState({ TotalHit: this.state.HitCountT + this.state.HitCountFTTH });
      //this.setState({ TotalCost: this.state.CostT + this.state.CostFtth });
    } catch (error) {
      console.log(error);
    }
    //this.setState({ TotalHit: this.state.HitCountT + this.state.HitCountFTTH });
    //this.setState({ TotalCost: this.state.CostT + this.state.CostFtth });
  }

  onClear() {
    this.setState({ FromDate: '', defaultfromDate: '' });
  }

  onClear2() {
    this.setState({ ToDate: '', defaulttoDate: '' });
  }

  CustomToolbar() {
    return (
      <GridToolbarContainer style={{ marginLeft: '93%' }}>
        <GridToolbarExport csvOptions={{
          fields: ['user_name', 'api_name', 'month_year', 'project_name', 'network_name', 'total_hit_count', 'hit_cost_inr77']
        }} />
      </GridToolbarContainer>
    );
  }


  componentDidMount() {
    this.PopulateData();
    this.CalculateApiHit();
    this.state.columns = [
      { field: 'id', headerName: '.', renderCell: (params) => (<strong> <a href="javascript:;" onClick={() => { this.openModal(params.id); }} ></a> </strong>), headerClassName: 'super-app-theme--header', headerAlign: 'center', width: 10 },
      { field: 'user_name', headerName: 'User Name', headerClassName: 'super-app-theme--header', headerAlign: 'center', width: 200 },
      { field: 'api_name', headerName: 'API Name', headerClassName: 'super-app-theme--header', headerAlign: 'center', width: 200 },
      { field: 'month_year', headerName: 'Month Year', headerClassName: 'super-app-theme--header', headerAlign: 'center', width: 200 },
      { field: 'project_name', headerName: 'Project Name', headerClassName: 'super-app-theme--header', headerAlign: 'center', width: 200 },
      { field: 'network_name', headerName: 'Network Name', headerClassName: 'super-app-theme--header', headerAlign: 'center', width: 200 },
      { field: 'total_hit_count', headerName: 'Total Hit Count', headerClassName: 'super-app-theme--header', headerAlign: 'center', width: 200 },
      { field: 'hit_cost_inr77', headerName: 'Hit Cost INR(*77)', headerClassName: 'super-app-theme--header', headerAlign: 'center', width: 280 },

    ];
  }
  render() {
    return (
      <div>
        <Formik>
          {formik => {
            return <Form>
              <BiztranControls.Select label="Group" name="group" defaultValue='Transport' onChange={this.handleChangeGroup} options={GroupOptions} />
              <BiztranControls.Input style={{ marginTop: 16, marginBottom: 35 }} label="User Name" name="UserName" id="username" />
              <BiztranControls.Input style={{ marginTop: 16, marginBottom: 35 }} label="Project Name" name="ProjectName" id="projectname" />
              <BiztranControls.Input style={{ marginTop: 16, marginBottom: 35 }} label="Network Name" name="NetworkName" id="networkname" />


              <div>
                <table>
                  <tr>
                    <td>
                      <div style={{ width: "223px", marginLeft: 6, marginBottom: 15 }}>
                        <label style={{ color: "grey", fontSize: 12 }}>From Date</label>
                        <DatePickerInput onChange={(jsDate, dateString) => this.setState({ FromDate: dateString })} locale="en" displayFormat="DD/MMM/YYYY"
                          className='my-react-component' onClear={this.onClear} showOnInputClick maxDate={new Date()} returnFormat="DD/MMM/YYYY" defaultValue={this.state.defaultfromDate}
                        />
                      </div>
                    </td>
                    <td>
                      <div style={{ width: "223px", marginLeft: 12, marginBottom: 15 }}>
                        <label style={{ color: "grey", fontSize: 12 }}>To Date</label>
                        <DatePickerInput onChange={(jsDate, dateString) => this.setState({ ToDate: dateString })} locale="en" displayFormat="DD/MMM/YYYY"
                          className='my-react-component' onClear={this.onClear2} showOnInputClick maxDate={new Date()} returnFormat="DD/MMM/YYYY" defaultValue={this.state.defaulttoDate}
                        />
                      </div>
                    </td>
                    <td>
                      <div style={{ marginTop: 15 }}>
                        <BiztranControls.Button text="POPULATE" backcolor={global.COLOR.Secondary} startIcon={<SearchIcon />} onClick={() => { this.PopulateData(); }} />
                      </div>
                    </td>
                    <td>
                      <div style={{ marginTop: 15, width: "150px" }}>
                        <label style={{ color: "grey", fontSize: 12 }}>FTTH Hit Count : </label><label style={{ color: "grey", fontSize: 12 }}>{this.state.HitCountFTTH}</label>
                      </div>
                      <div style={{ marginTop: -14 }}>
                        <label style={{ color: "grey", fontSize: 12 }}>FTTH Hit Cost  : </label><label style={{ color: "grey", fontSize: 12 }}>{this.state.CostFtth}</label>
                      </div>
                    </td>
                    <td>
                      <div style={{ marginTop: 15, width: "160px" }}>
                        <label style={{ color: "grey", fontSize: 12 }}>Transport Hit Count: </label><label style={{ color: "grey", fontSize: 12 }}>{this.state.HitCountT}</label>
                      </div>
                      <div style={{ marginTop: -14 }}>
                        <label style={{ color: "grey", fontSize: 12 }}>Transport Hit Cost : </label><label style={{ color: "grey", fontSize: 12 }}>{this.state.CostT}</label>
                      </div>
                    </td>
                    <td>
                      <div style={{ marginTop: 15, width: "150px" }}>
                        <label style={{ color: "grey", fontSize: 12 }}>Total Hit Count: </label><label style={{ color: "grey", fontSize: 12 }}>{this.state.TotalHit}</label>
                      </div>
                      <div style={{ marginTop: -14 }}>
                        <label style={{ color: "grey", fontSize: 12 }}>Total Hit Cost : </label><label style={{ color: "grey", fontSize: 12 }}>{this.state.TotalCost}</label>
                      </div>
                    </td>
                  </tr>
                </table>

                <BiztranControls.DataGrid rows={this.state.rows} columns={this.state.columns} rowHeight={25} checkboxSelection={false} />

              </div>
            </Form>
          }

          }
        </Formik>
      </div>
    );
  }
}

