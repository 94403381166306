import React from 'react';
import Autocomplete from '@material-ui/lab/Autocomplete';
import { makeStyles } from '@material-ui/core/styles';
import TextField from '@material-ui/core/TextField';

const useStyles = makeStyles((theme) => ({
    textField: {
        margin: theme.spacing(1),
        width: '27ch',
      },
      input: {
        height: 16,
        fontSize:7,
      },
}));

export default function MultiselectAutocomplete() {
  const classes = useStyles();

  return (
    <div>
      
      <Autocomplete
        multiple
        id="tags-outlined"
        className={classes.textField}
        options={BiztranTeam}
        getOptionLabel={(option) => option.title}
        filterSelectedOptions
        renderInput={(params) => (
          <TextField
            {...params}
            variant="outlined"
            label="MultiSelect Autocomplete"
            className={classes.input}
            InputLabelProps={{ shrink: true, }}
          />
        )}
      />
    </div>
  );
}

const BiztranTeam = [
  { title: 'George', year: 1974  },
  { title: 'Omkar', year: 1974 },
  { title: 'Namrata', year: 1974 },
  { title: 'Priti', year: 2008 },
  { title: 'Rohit', year: 1957 },
  { title: "Sujit", year: 1993 },
  { title: 'Neetish', year: 1994 },
  { title: 'Nitesh', year: 2003 },
  { title: 'Shubham', year: 1966 },
  { title: 'Varsha', year: 1999 },
  { title: 'Jay', year: 2001 },
  { title: 'Anju', year: 1980 },
  { title: 'Nikhil', year: 1994 },
];

