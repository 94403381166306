import React, {useState} from 'react';
import {Formik, Form} from 'formik';
import axios from 'axios';
import SearchIcon from '@material-ui/icons/Search';
import AddCircleOutlineIcon from '@material-ui/icons/AddCircleOutline';
import BiztranControls from '../../BiztranControls/BiztranControls';
import Modal_Frame from '../../BiztranControls/Modal_Frame';
import global from './../Global';
import FTTHCreateProductMaster from './FTTH_Create_Product_Master';
  
let ProductID='';

export default class Product_Master extends React.Component {
    constructor(props) {
        super(props);
        this.PopulateData = this.PopulateData.bind(this);
        this.openModal = this.openModal.bind(this);
        this.openAddModal = this.openAddModal.bind(this);
        this.state ={
          rows:[], columns:[], isPopOpen:false
        };
    }

    openModal(row){
        ProductID = row;
        this.setState({isPopOpen:true});
    }

    openAddModal(){
        ProductID = "";
        this.setState({isPopOpen:true});
    }

    handleClose = ()=>{
        this.setState({isPopOpen:false});
    }

    PopulateData(){
        var searchString = document.getElementById("productname").value;
        var bodyFormData = new FormData();
        bodyFormData.append('ProductID','');
        bodyFormData.append('MainGroup', 'FTTH');
        bodyFormData.append('SearchString', searchString);
        axios({
            url: global.BASE_URL+"Product/List",
            method: 'post',
            headers: {'Content-Type': 'multipart/form-data' },
            data: bodyFormData
        })
        .then((response) => {
            this.setState({rows:response.data});
        })
        .catch((response) => {
            console.log(response);
        }); 
    }

    componentDidMount(){
        this.PopulateData();
        this.state.columns = [
            { field: 'id', headerName: '.', renderCell: (params) => (<strong> <a href="javascript:;" onClick={() => { this.openModal(params.id); }} >Edit</a> </strong> ), headerClassName: 'super-app-theme--header', headerAlign: 'center', width: 70 },
            { field: 'group_name', headerName: 'Product Group', headerClassName: 'super-app-theme--header', headerAlign: 'center', width: 160 },
            { field: 'product_code', headerName: 'Product Code', headerClassName: 'super-app-theme--header', headerAlign: 'center', width: 140 },
            { field: 'product_name', headerName: 'Product Name', headerClassName: 'super-app-theme--header', headerAlign: 'center', width: 230 },
            { field: 'product_specification', headerName: 'Product Specification', headerClassName: 'super-app-theme--header', headerAlign: 'center', width: 220 },
            { field: 'uom', headerName: 'UOM', headerClassName: 'super-app-theme--header', headerAlign: 'center', width: 100 },
            { field: 'std_rate', headerName: 'STD Rate', headerClassName: 'super-app-theme--header', headerAlign: 'center', width: 110 },
            { field: 'bos_item_code', headerName: 'BOS Item Code', headerClassName: 'super-app-theme--header', headerAlign: 'center', width: 160 },
            { field: 'bos_item_name', headerName: 'BOS Item Name', headerClassName: 'super-app-theme--header', headerAlign: 'center', width: 180 },
            { field: 'service_rate', headerName: 'Service Rate', headerClassName: 'super-app-theme--header', headerAlign: 'center', width: 140 },
            { field: 'is_active', headerName: 'IsActive', headerClassName: 'super-app-theme--header', headerAlign: 'center', width: 100 },
        ];
    }

    render(){
        return(
            <div>
                <Formik>
                    {formik => {
                        return <Form>
                            <BiztranControls.Input style={{marginTop:16}} label="Product Name" name="ProductName" id="productname" />
                            <BiztranControls.Button backcolor={global.COLOR.Secondary} text="POPULATE" startIcon={<SearchIcon/>}  onClick={() => { this.PopulateData(); }} />
                            <BiztranControls.Button backcolor={global.COLOR.Secondary} text="ADD NEW" startIcon={<AddCircleOutlineIcon/>} onClick={() => { this.openAddModal(); }} />                       
                            <Modal_Frame title="FTTH Product Master" maxWidth="sm" isOpen={this.state.isPopOpen} onClose={this.handleClose}>
                                <FTTHCreateProductMaster ProductID={ProductID}></FTTHCreateProductMaster>
                            </Modal_Frame> 
                            <BiztranControls.DataGrid rows={this.state.rows} columns={this.state.columns} rowHeight={25} checkboxSelection={false} />
                        </Form>
                    }}
                </Formik>
            </div>
        );
    }
}