import Input from "./Input";
import RadioGroup from "./RadioGroup";
import Select from "./Select";
import Checkbox from "./Checkbox";
import Button from "./Button";
import DataGrid from "./DataGrid";
import Autocomplete from "./Autocomplete"
//import DesktopDatePicker from "./DesktopDatePicker";
const BiztranControls = {
  Input,
  RadioGroup,
  Select,
  Checkbox,
  Button,
  DataGrid,
  Autocomplete,
  /* DesktopDatePicker */
};

export default BiztranControls;
