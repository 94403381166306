import Input from "./Input";
import RadioGroup from "./RadioGroup";
import Select from "./Select";
import Checkbox from "./Checkbox";
import DatePicker from "./DatePicker";
import Button from "./Button";
import DataGrid from "./DataGrid";
import MultiselectAutocomplete from "./MultiselectAutocomplete";

const BiztranControls = {
    Input,
    RadioGroup,
    Select,
    Checkbox,
    DatePicker,
    Button,
    DataGrid,
    MultiselectAutocomplete,
}

export default BiztranControls;