import { useState } from "react";
import axios from "axios";
import Global from "../../components/Global";
const UseFetchData = () => {
  const [response, setResponse] = useState([]);
  const fetchData = async (uri, params) => {
    const resp = await axios({
      url: Global.BASE_URL + uri,
      method: "post",
      headers: { "Content-Type": "multipart/form-data" },
      data: params,
    });
    setResponse(resp.data);
  };
  return [response, fetchData];
};

export default UseFetchData;
