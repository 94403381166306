import React , { useState,useEffect }  from 'react';
import Grid from '@material-ui/core/Grid';
import SaveIcon from '@material-ui/icons/Save';
import {Formik, Form} from 'formik';
import * as Yup from 'yup';
import UseFetchData from "../hooks/UseFetchData";
import BiztranControls from "../biztran_controls/BiztranControls";
import BiztranControlsFormik from "../biztran_controls_formik/BiztranControlsFormik";
import global from '../Global';
import axios from 'axios';

const SurveyAssign = (props) => {
    const [Surveyor,setSurveyor] = useState([]);
    const [response, fetchData] = UseFetchData();

    const initialValues = {
        SurveyorID:''
    }
      
    const validationSchema = Yup.object({
        SurveyorID:Yup.string().required('Required!')
    })
    
    const onSubmit = values => {
        var UserID = localStorage.getItem("UserID");
        var bodyFormData = new FormData();
        bodyFormData.append('SurveyID',props.SurveyID);
        bodyFormData.append('UserID',UserID);
        bodyFormData.append('SurveyorID',values.SurveyorID);
        fetchData("SurveyAPI/AssignToSurveyor", bodyFormData); 
    }

    const FillSurveyor = () => {
        axios.post(global.BASE_URL+"SurveyAPI/FillSurveyor").then((response) => {
            setSurveyor(response.data);
        });
      }
    
    const  Surveyordata = () => {
        if(Surveyor.length > 0){
            let SurveyorCombo = Surveyor.map((data) =>
                <option
                    key={data.surveyorname}
                    value={data.surveyorid}
                >
                    {data.surveyorname}
                </option>
            );
            return SurveyorCombo;
        }
    }

    useEffect(() => {
        FillSurveyor();
    }, []);

    useEffect(() => {
        if(response.length > 0 || response.length === undefined){
            if(response.Response === true){
                props.handleCloseModal();
                alert("Saved Successfully.");
            }
            else{
                alert("Save Failed.");
            }
        }
    }, [response]);

    let SurveyorCombo = Surveyordata();

    return(
        <div>
            <Formik initialValues = {initialValues} validationSchema = {validationSchema} onSubmit = {onSubmit} enableReinitialize>
            {formik => {    
                return <Form>
                        <Grid container spacing={2}>
                            <Grid item xs={12}>    
                            <BiztranControlsFormik control='select' label='Surveyor Name' name='SurveyorID' options={SurveyorCombo} style={{ height:'30px', width:'100%' }} />
                                <BiztranControls.Button type="submit" style={{color:'white', backgroundColor:global.COLOR.Secondary, marginTop:'10px', width:160}} startIcon={<SaveIcon />}
                                    text="Add Surveyor"
                                />                            
                            </Grid>
                        </Grid>
                    </Form>
                }
            }
            </Formik> 
        </div>
    );
}

export default SurveyAssign;
