import React from 'react'
import { TextField } from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';

const useStyles = makeStyles((theme) => ({
    textField: {
      marginLeft: theme.spacing(1),
      marginRight: theme.spacing(1),
      width: '25ch',
      height: '10px',
    },
    input: {
      height: 34,
      fontSize:16,
    },
  }));

export default function Input(props) {

    const { name, label, defaultValue, value, id, disabled, error=null, type, onChange, ...rest } = props;
    const classes = useStyles();
    return (
        <TextField
            variant="outlined"
            size="small"
            type={type}
            label={label}
            className={classes.textField}
            {...rest}
            name={name}
            defaultValue = {defaultValue}
            value={value}
            id={id}
            InputProps={{
              className: classes.input
            }}
            disabled={disabled}
            InputLabelProps={{ shrink: true, }}
            onChange={onChange}
            {...(error && {error:true,helperText:error})}
        />
    )
}
