import React, { useState, useEffect } from 'react';
import Grid from '@material-ui/core/Grid';
import SaveIcon from '@material-ui/icons/Save';
import DeleteIcon from '@material-ui/icons/Delete';
import { Formik, Form } from 'formik';
import * as Yup from 'yup';
import UseFetchData from "../hooks/UseFetchData";
import BiztranControls from "../biztran_controls/BiztranControls";
import BiztranControlsFormik from "../biztran_controls_formik/BiztranControlsFormik";

const AddQuestion = (props) => {
    const [Mode, setMode] = useState('');
    const [SurveyTempDtlsID, setSurveyTempDtlsID] = useState('');
    const [SurveyTempID, setSurveyTempID] = useState('');
    const [QuestionType, setQuestionType] = useState('');
    const [Seq, setSeq] = useState('');
    const [QuestionDesc, setQuestionDesc] = useState('');
    const [GuidanceHint, setGuidanceHint] = useState('');
    const [IsMandatory, setIsMandatory] = useState('');
    const [Disable, setDisable] = useState(true);
    const [ChoiceOptions, setChoiceOptions] = useState([]);
    const [response, fetchData] = UseFetchData();
    const [responseDelete, fetchDataDelete] = UseFetchData();
    const [responseQtnDtls, fetchDataQtnDlts] = UseFetchData();

    const validationSchema = Yup.object({
        QuestionType: Yup.string().required('Required!'),
        Seq: Yup.string().required('Required!'),
        QuestionDesc: Yup.string().required('Required!'),
        //IsMandatory:Yup.string().required('Required!')
    });

    const onSubmit = values => {
        setMode('Insert');
        var IsMandatory = '';
        if (values.IsMandatory === true) {
            IsMandatory = 'Y';
        } else {
            IsMandatory = 'N';
        }
        var ChoiceOptions = [];
        if (values.ChoiceOptions.length > 0) {
            ChoiceOptions = values.ChoiceOptions;
            ChoiceOptions = ChoiceOptions.replaceAll('\n', ',')
        }
        var bodyFormData = new FormData();
        bodyFormData.append('SurveyTempDtlsID', props.SurveyTempDtlsID);
        bodyFormData.append('SurveyTempID', SurveyTempID);
        bodyFormData.append('QuestionType', values.QuestionType);
        bodyFormData.append('Sequence', values.Seq);
        bodyFormData.append('QuestionDesc', values.QuestionDesc);
        bodyFormData.append('GuidanceHint', values.GuidanceHint);
        bodyFormData.append('ChoiceOptions', ChoiceOptions.toString());
        bodyFormData.append('IsMandatory', IsMandatory);
        fetchData("SurveyAPI/InsertSurveyTemplatesDtls", bodyFormData);
    };

    const QuetionTypeOptions = [
        { key: 'Single Line Answer', value: 'Single Line Answer' },
        { key: 'Multi Line Answer', value: 'Multi Line Answer' },
        { key: 'Dropdown', value: 'Dropdown' },
        { key: 'Multiple Choice', value: 'Multiple Choice' },
        { key: 'Check Boxes', value: 'Check Boxes' },
        { key: 'Date Time', value: 'Date Time' },
        { key: 'Attachment-Single', value: 'Attachment-Single' },
        { key: 'Attachment-Multiple', value: 'Attachment-Multiple' }
    ];

    const QueTypedata = () => {
        let QueTypeCombo = QuetionTypeOptions.map((data) =>
            <option
                key={data.key}
                value={data.value}
            >
                {data.key}
            </option>
        );
        return QueTypeCombo;
    }

    const DeleteTempQtnConfirm = () => {
        try {
            const confirmBox = window.confirm(
                "Do you really want to delete Qtn?"
            )
            if (confirmBox === true) {
                DeleteTempQtn();
            }
        }
        catch (error) {
            console.log(error);
        }
    }

    const DeleteTempQtn = () => {
        try {

            var bodyFormData = new FormData();
            bodyFormData.append('SurveyTempDtlsID', SurveyTempDtlsID);
            fetchDataDelete("SurveyAPI/DeleteSurveyTemplateQtn", bodyFormData);
        }
        catch (error) {
            console.log(error);
        }
    }

    const GetQuestionDetails = () => {
        try {
            setSurveyTempDtlsID(props.SurveyTempDtlsID);
            var bodyFormData = new FormData();
            bodyFormData.append('TemplateDetailID', props.SurveyTempDtlsID);
            fetchDataQtnDlts("SurveyAPI/GetAddQuesDetails", bodyFormData);
        } catch (error) {
            console.log(error);
        }
    }

    const handleChange = e => {
        setQuestionType(e.target.value);
        if (e.target.value === "Dropdown" || e.target.value === "Multiple Choice" || e.target.value === "Check Boxes" || e.target.value === "Attachment-Multiple") {
            setDisable(false);
        }
        else {
            setDisable(true);
        }
    }

    useEffect(() => {
        var IsMandatory = false;

        if (responseQtnDtls.length > 0) {
            if (responseQtnDtls[0].is_mandatory === "Y") {
                IsMandatory = true;
            } else {
                IsMandatory = false;
            }
            if (responseQtnDtls[0].question_type === "Dropdown" || responseQtnDtls[0].question_type === "Multiple Choice" || responseQtnDtls[0].question_type === "Check Boxes" || responseQtnDtls[0].question_type === "Attachment-Multiple") {
                setDisable(false);
            }
            else {
                setDisable(true);
            }
            if (responseQtnDtls[0].choice_options !== null) {

                var ChoiceOption = responseQtnDtls[0].choice_options;
                setChoiceOptions(ChoiceOption.join("\n"));
            }
            else {
                setChoiceOptions([]);
            }
            setQuestionType(responseQtnDtls[0].question_type);
            setSeq(responseQtnDtls[0].seq);
            setQuestionDesc(responseQtnDtls[0].question_description);
            setGuidanceHint(responseQtnDtls[0].guidance_hint);
            setIsMandatory(IsMandatory);
        }
    }, [responseQtnDtls]);

    useEffect(() => {

        if (response.length > 0 || response.length === undefined) {
            if (response.Response === true) {
                props.handleClose();
                props.PopulateData();
                alert("Saved Successfully.");
            }
            else {
                alert("Save Failed.");
            }
        }
    }, [response]);

    useEffect(() => {

        if (responseDelete.length > 0 || responseDelete.length === undefined) {
            if (responseDelete.Response === true) {
                props.handleClose();
                props.PopulateData();
                alert("Deleted Successfully.");
            }
            else {
                alert("Delete Failed.");
            }
        }
    }, [responseDelete]);

    useEffect(() => {

        setSurveyTempID(props.SurveyTempID);
        if (props.SurveyTempDtlsID !== '') {
            setMode('Update');
            GetQuestionDetails();
        }
        else {
            setMode('Insert');
        }
    }, []);

    let QueTypeCombo = QueTypedata();
    return (
        <div>
            <Formik initialValues={{ QuestionType: QuestionType, Seq: Seq, QuestionDesc: QuestionDesc, GuidanceHint: GuidanceHint, ChoiceOptions: ChoiceOptions, IsMandatory: IsMandatory }}
                validationSchema={validationSchema} onSubmit={onSubmit} enableReinitialize>
                {formik => {
                    return <Form>
                        <Grid container spacing={1}>
                            <Grid item xs={6}>
                                <BiztranControlsFormik control='select' type='text' label='Question Type' name='QuestionType' options={QueTypeCombo} onChange={handleChange} style={{ height: '30px', width: '270px' }} />
                            </Grid>
                            <Grid item xs={6}>
                                <BiztranControlsFormik control='input' type='text' label='Seq' name='Seq' onChange={event => setSeq(event.target.value)} style={{ height: '30px', width: '100%' }} />
                            </Grid>
                            <Grid item xs={12}>
                                <BiztranControlsFormik control='input' type='text' label='Question Desc' onChange={event => setQuestionDesc(event.target.value)} name='QuestionDesc' style={{ height: '30px', width: '100%' }} />
                            </Grid>
                            <Grid item xs={12}>
                                <BiztranControlsFormik control='input' type='text' label='Guidence Hint' name='GuidanceHint' onChange={event => setGuidanceHint(event.target.value)} style={{ height: '30px', width: '100%' }} />
                            </Grid>
                            <Grid item xs={12}>
                                <BiztranControlsFormik control='textarea' type='text' label='Choice Options' disabled={Disable} name='ChoiceOptions' style={{ height: '70px', width: '100%' }} />
                            </Grid>
                            <Grid item xs={3}>
                                <BiztranControlsFormik control='input' type='checkbox' label='Is Mandatory' name='IsMandatory' style={{ height: '34px' }} />
                            </Grid>
                        </Grid>
                        <BiztranControls.Button type="submit" startIcon={<SaveIcon />}
                            text="Save"
                        />
                        <BiztranControls.Button onClick={() => { DeleteTempQtnConfirm(); }} disabled={props.btnDeleteDisable} style={{ color: 'white', backgroundColor: props.btnDeleteColor, marginTop: '10px' }} startIcon={<DeleteIcon />}
                            text="Delete"
                        />
                    </Form>
                }}
            </Formik>
        </div>
    );
}

export default AddQuestion;
