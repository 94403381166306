import React from 'react';
import Grid from '@material-ui/core/Grid';
import SaveIcon from '@material-ui/icons/Save';
import {Formik, Form} from 'formik';
import * as Yup from 'yup';
import global from './../Global';
import BiztranContorls_01 from '../../BiztranControls/BiztranControls';
import BiztranControl from '../../BiztranControls_01/BiztranControls';
import axios from 'axios';

const initialValues = {
   ProductCode:'',ProductName:'',ProductType:'',ProductSpec:'',MainGroup:'',ProductGroup:'',UOM:'',
   STDRate:'',BOSItemCode:'',BOSItemName:'',ServiceRate:'',IsActive:''
}

let ProductID = "";

const CurDate = new Date().getFullYear() + '-' + (new Date().getMonth() + 1) + '-' + new Date().getDate();

const validationSchema = Yup.object({
    ProductCode:Yup.string().required('Required!'),
    ProductName:Yup.string().required('Required!'),
    ProductSpec:Yup.string().required('Required!'),
    ProductGroup:Yup.string().required('Required!'),
    UOM:Yup.string().required('Required!'),
    STDRate:Yup.string().required('Required!'),
    BOSItemCode:Yup.string().required('Required!'),
    BOSItemName:Yup.string().required('Required!'),
    ServiceRate:Yup.string().required('Required!'),
    isActive:Yup.string().required('Required!')
})

const onSubmit = values => {
    var bodyFormData = new FormData();
    bodyFormData.append('MainGroup','Transport');
    bodyFormData.append('ProductID',ProductID);
    bodyFormData.append('ProductGroupID',values.ProductGroup);
    bodyFormData.append('ProductCode',values.ProductCode);
    bodyFormData.append('ProductName',values.ProductName);
    bodyFormData.append('ProductSpec',values.ProductSpec);
    bodyFormData.append('UOM',values.UOM);
    bodyFormData.append('STDRate',values.STDRate);
    bodyFormData.append('BOSItemCode',values.BOSItemCode);
    bodyFormData.append('BOSItemName',values.BOSItemName);
    bodyFormData.append('ServiceRate',values.ServiceRate);
    bodyFormData.append('IsActive',values.isActive);
    axios({
        url: global.BASE_URL+"Product/Save",
        method: 'post',
        headers: {'Content-Type': 'multipart/form-data'},
        data: bodyFormData
    })
    .then(function (response) {
        alert("Imported Successfully.");
    })
    .catch(function (response) {
        console.log(response);
    }); 
} 

const IsActiveOptions = [
    { key: 'Yes', value:'Y' },
    { key: 'No', value:'N' }
]

const UOMOptions = [
    { key: 'Mtrs', value:'Mtrs' },
    { key: 'Nos', value:'Nos' }
]

export default class Product_Create extends React.Component {

    constructor(props) {
        super(props);
        this.FillProductGroup = this.FillProductGroup.bind(this);
        this.state = {
            Product_Group:[],ProductGroup:'',ProductCode:'',ProductName:'',ProductSpec:'',UOM:'',STDRate:'',BOSItemCode:'',BOSItemName:'',ServiceRate:'',IsActive:'',
        };
    }

    FillProductGroup() {
        var bodyFormData = new FormData();
        bodyFormData.append('MainGroup', 'Transport');
        axios({
            url: global.BASE_URL+"Product/ComboData",
            method: 'post',
            headers: {'Content-Type': 'multipart/form-data' },
            data: bodyFormData
        })
        .then((response) => {
            this.setState({Product_Group:response.data.ProductGroup});
        })
        .catch((e) => {
            console.log(e);
        });
    }

    ProductGroupdata() {
        let ProductGroupCombo = this.state.Product_Group.map((data) =>
            <option
                key={data.group_name}
                value={data.product_group_id}
            >
                {data.group_name}
            </option>
        );
        return ProductGroupCombo;
    }

    UOMdata() {
        let UOMCombo = UOMOptions.map((data) =>
            <option
                key={data.key}
                value={data.value}
            >
                {data.key}
            </option>
        );
        return UOMCombo;
    }

    OnChangeProductName() {
        var ProSpec = this.state.Product_Group + "-" + this.state.Product_Name;
        this.setState({Product_Spec: ProSpec});
    }

    GetProductData(){
        ProductID = this.props.ProductID;
        var bodyFormData = new FormData();
        bodyFormData.append('ProductID',ProductID);
        axios({
            url: global.BASE_URL+"Product/GetByID",
            method: 'post',
            headers: {'Content-Type': 'multipart/form-data' },
            data: bodyFormData
        })
        .then((response) => {
            var IsActive;
            if(response.data[0].is_active == true){
                IsActive = "Y"
            } else{
                IsActive = "N"
            }
            if(response.data[0].std_rate == "" || response.data[0].std_rate == null){
                this.setState({STDRate:response.data[0].std_rate});
            }else{
                this.setState({STDRate:(response.data[0].std_rate).toString()});
            }
            if(response.data[0].service_rate == "" || response.data[0].service_rate == null){
                this.setState({ServiceRate:response.data[0].service_rate});
            }else{
                this.setState({ServiceRate:(response.data[0].service_rate).toString()});
            }
            this.setState({ ProductGroup:response.data[0].product_group_id,
                ProductCode:response.data[0].product_code,
                ProductName:response.data[0].product_name,
                ProductSpec:response.data[0].product_specification,
                UOM:response.data[0].uom,
                BOSItemCode:response.data[0].bos_item_code,
                BOSItemName:response.data[0].bos_item_name,
                IsActive:IsActive
            });
        })
        .catch((e) => {
            console.error(e);
        });
    }
    
    componentDidMount() {
        this.FillProductGroup();
        if(this.props.ProductID != ""){
            this.GetProductData();
        }
    }

    render(){
       let ProductGroupCombo = this.ProductGroupdata();
       let UOMCombo = this.UOMdata();
    return(
        <div>
            <Formik initialValues = {{  ProductGroup:this.state.ProductGroup, ProductCode:this.state.ProductCode, ProductName:this.state.ProductName, ProductSpec:this.state.ProductSpec,
           UOM:this.state.UOM, STDRate:this.state.STDRate, BOSItemCode:this.state.BOSItemCode, BOSItemName:this.state.BOSItemName, ServiceRate:this.state.ServiceRate, isActive:this.state.IsActive }}
                validationSchema = {validationSchema} onSubmit={onSubmit} enableReinitialize>
            {formik => {
                return <Form>
                        <Grid container spacing={1}>
                            <Grid item xs={6}>
                                <BiztranControl control='select' type='text' label='Product Group' name='ProductGroup' options={ProductGroupCombo} style={{ height:'30px', width:'270px'}} />
                            </Grid>
                            <Grid item xs={6}>
                                <BiztranControl control='input' type='text' label='Product Code' name='ProductCode' style={{ height:'30px', width:'265px'}} />
                            </Grid>
                            <Grid item xs={6}>
                                <BiztranControl control='input' type='text' label='Product Name' name='ProductName' style={{ height:'30px', width:'270px'}} />
                            </Grid>
                            <Grid item xs={6}>
                                <BiztranControl control='input' type='text' label='Product Specification' name='ProductSpec' style={{ height:'30px', width:'265px'}} />
                            </Grid>
                            <Grid item xs={6}>
                                <BiztranControl control='select' type='text' label='UOM' name='UOM' options={UOMCombo} style={{ height:'30px', width:'270px'}} />
                            </Grid>
                            <Grid item xs={6}>        
                                <BiztranControl control='input' type='text' label='STD Rate' name='STDRate' style={{ height:'30px', width:'265px'}} />
                            </Grid>
                            <Grid item xs={6}>
                                <BiztranControl control='input' type='text' label='BOS Item Code' name='BOSItemCode' style={{ height:'30px', width:'270px'}} />
                            </Grid>
                            <Grid item xs={6}>    
                                <BiztranControl control='input' type='text' label='BOS Item Name' name='BOSItemName' style={{ height:'30px', width:'265px'}} />
                            </Grid>
                            <Grid item xs={6}>
                                <BiztranControl control='input' type='text' label='Service Rate' name='ServiceRate' style={{ height:'30px', width:'270px'}} />
                            </Grid>
                            <Grid item xs={6}>
                                <BiztranControl control='radio' label='IsActive' name='isActive' options={IsActiveOptions} /> 
                            </Grid>
                        </Grid>
                        <BiztranContorls_01.Button backcolor={global.COLOR.Secondary} type="submit" style={{color:'white', marginTop:'10px',backgroundColor:global.COLOR.Secondary}} startIcon={<SaveIcon />} 
                            text="Save"
                        />
                    </Form>
                }
            }
            </Formik> 
        </div>
    );
}
}