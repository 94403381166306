import React, { Component } from 'react';

class Home extends Component {
  render() {
    return(
      <h5>Welcome..</h5>
    );
  }
}

export default Home;
