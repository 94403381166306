import React, { Component } from 'react';
import { BrowserRouter, Route, Switch, Redirect } from 'react-router-dom';
import 'bootstrap/dist/css/bootstrap.min.css';
import './custom.css'
import Login from './components/Login';
import Navbar from "./BiztranControls/Navbar";
import Create_FTTH_Project from './components/Masters/Create_FTTH_Project';
import { PrivateRoute } from '../src/PrivateRoute';


export default class App extends Component {
  static displayName = App.name;

  render() {
    return (
      <BrowserRouter>
        <Switch>
          <Route exact path="/login" component={LoginContainer} />
          <PrivateRoute exact path="/Home" component={DefaultContainer} />
          <PrivateRoute path="/" component={DefaultContainer} />
          <Redirect from="*" to="/Home" />
        </Switch>
      </BrowserRouter>
    );
  }
}

const LoginContainer = () => (
  <Route exact path="/login" component={Login} />
)

const DefaultContainer = () => (
  <div>
    <Navbar />
  </div>
)
