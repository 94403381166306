import React, { useState, useEffect } from 'react';
import Grid from '@material-ui/core/Grid';
import { withStyles } from '@material-ui/styles';
import SaveIcon from '@material-ui/icons/Save';
import { Formik, Form } from 'formik';
import { DataGrid } from '@material-ui/data-grid';
import UseFetchData from "../hooks/UseFetchData";
import BiztranControls from "../biztran_controls/BiztranControls";
import global from '../Global';
import axios from 'axios';

let SurveyID = '';

const styles = theme => ({
    root: {
        '& .super-app-theme--header': {
            backgroundColor: global.COLOR.Secondary,
            color: 'white',
            fontSize: 16,
        },
    },
});

const AddMultiSurveyTemp = (props) => {
    const [rows, setrows] = useState([]);
    //const [selectionModel, setselectionModel] = useState(['089d507c-968f-4a12-8640-f1223585902d']);
    const [selectionModel, setselectionModel] = useState([]);
    const [SurveyTempID, setSurveyTempID] = useState([]);
    const [responseSurveyTempDtls, fetchDataSurveyTempDtls] = UseFetchData();
    const [response, fetchData] = UseFetchData();

    const columns = [
        { field: 'template_type', headerName: 'Template Type', headerClassName: 'super-app-theme--header', headerAlign: 'center', width: 205 },
        { field: 'tempname', headerName: 'Template Name', headerClassName: 'super-app-theme--header', headerAlign: 'center', width: 205 },
        { field: 'description', headerName: 'Description', headerClassName: 'super-app-theme--header', headerAlign: 'center', width: 205 },
        { field: 'data_capture_mode', headerName: 'Data Capture Mode', headerClassName: 'super-app-theme--header', headerAlign: 'center', width: 205 },
    ];

    const PopulateData = () => {
        try {
            var bodyFormData = new FormData();
            bodyFormData.append('SurveyID', props.SurveyID);
            bodyFormData.append('SurveyType', props.SurveyType);

            fetchDataSurveyTempDtls("SurveyAPI/MultiSurveyTemplateData", bodyFormData);
        } catch (error) {
            console.log(error);
        }
    }

    const currentlySelected = (selections) => {

        setSurveyTempID(selections.rowIds);
    }

    const AddSurveyTemp = () => {
        try {

            if (SurveyTempID.length > 0) {

                var bodyFormData = new FormData();
                for (var i = 0; i < SurveyTempID.length; i++) {
                    bodyFormData.append('SurveyTemplateID[]', SurveyTempID[i]);
                }
                bodyFormData.append('SurveyID', props.SurveyID);
                fetchData("SurveyAPI/AddMultipleSurveyTemp", bodyFormData);
            }
        }
        catch (error) {
            console.log(error);
        }
    }

    useEffect(() => {

        if (response.length > 0 || response.length === undefined) {
            if (response.Response === true) {
                props.handleCloseSurTempModal();
                props.GetSurveyTemplateData();
                alert("Saved Successfully.");
            }
            else {
                alert("Save Failed.");
            }
        }
    }, [response]);

    useEffect(() => {
        setrows(responseSurveyTempDtls);

        const selectedValues = responseSurveyTempDtls.filter(r => r.isexistingnetworkuser === 1)
            .map(filteredrows => (filteredrows.id));
        setselectionModel(selectedValues);

        //console.log(selectedValues);
        //console.log(responseSurveyTempDtls);

        // const selectedValues = React.useMemo(() => {
        //     
        //     return rows.filter((r) => r.isexistingnetworkuser === 1).map((r) => r.id);
        // }, rows);

    }, [responseSurveyTempDtls]);

    useEffect(() => {

        PopulateData();
    }, []);

    const { classes } = props;

    return (
        <div>
            <Formik enableReinitialize>
                {formik => {
                    return <Form>
                        <Grid container spacing={2}>
                            <Grid item xs={12}>
                                <BiztranControls.Button style={{ color: 'white', backgroundColor: global.COLOR.Secondary, marginTop: '10px' }} startIcon={<SaveIcon />} onClick={AddSurveyTemp}
                                    text="Add"
                                />
                                <div style={{ width: '100%', height: "250px", marginTop: 3 }} className={classes.root} >
                                    <DataGrid
                                        rows={rows}
                                        columns={columns}
                                        pageSize={25}
                                        rowHeight={22}
                                        headerHeight={34}
                                        checkboxSelection
                                        hideFooterPagination
                                        selectionModel={selectionModel}
                                        onSelectionModelChange={(newSelection) => {
                                            //console.log(newSelection);
                                            setSurveyTempID(newSelection.selectionModel);
                                        }}
                                    />
                                </div>
                            </Grid>
                        </Grid>
                    </Form>
                }
                }
            </Formik>
        </div>
    );
}

export default withStyles(styles)(AddMultiSurveyTemp);
