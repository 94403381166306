import React, { useEffect, useState } from 'react';
import { withStyles, makeStyles } from '@material-ui/core/styles';
import { Menu, Tooltip, MenuItem, Grid, Avatar, Badge, IconButton } from '@material-ui/core';
import ExitToAppIcon from "@material-ui/icons/ExitToApp";
import HttpsIcon from '@material-ui/icons/Https';
import Camera from "../media/images/icons/Camera.png"
import BiztranControls from "../BiztranControls/BiztranControls";
import UseFetchData from "../components/hooks/UseFetchData";
import Global from './Global';
// import Modal_Frame from "../components/biztran_controls/Modal_Frame";
// import ChangePassword from "../masters/usermaster/ChangePassword";


const StyledMenu = withStyles({
  paper: {
    border: '1px solid #d3d4d5',
  },
})((props) => (
  <Menu
    elevation={0}
    getContentAnchorEl={null}
    anchorOrigin={{
      vertical: 'bottom',
      horizontal: 'center',
    }}
    transformOrigin={{
      vertical: 'top',
      horizontal: 'center',
    }}
    {...props}
  />
));

const StyledBadge = withStyles((theme) => ({
  badge: {
    backgroundColor: '#44b700',
    color: '#44b700',
    boxShadow: `0 0 0 2px ${theme.palette.background.paper}`,
    '&::after': {
      position: 'absolute',
      top: 0,
      left: 0,
      width: '100%',
      height: '100%',
      borderRadius: '50%',
      animation: '$ripple 1.2s infinite ease-in-out',
      border: '1px solid currentColor',
      content: '""',
    },
  },
  '@keyframes ripple': {
    '0%': {
      transform: 'scale(.8)',
      opacity: 1,
    },
    '100%': {
      transform: 'scale(2.4)',
      opacity: 0,
    },
  },
}))(Badge);

const SmallAvatar = withStyles((theme) => ({
  root: {
    width: 22,
    height: 22,
    border: `2px solid ${theme.palette.background.paper}`,
  },
}))(Avatar);

const useStyles = makeStyles((theme) => ({
  large: {
    width: theme.spacing(25),
    height: theme.spacing(25),
  },
}));

const StyledMenuItem = withStyles((theme) => ({
  root: {
    '&:focus': {
      backgroundColor: 'white', //theme.palette.primary.main,
      '& .MuiListItemIcon-root, & .MuiListItemText-primary': {
        color: theme.palette.common.white,
      },
    },
  },
}))(MenuItem);

export default function ProfilePage(props) {
  const [anchorEl, setAnchorEl] = useState(null);
  const [MainState, setMainState] = useState('initial');
  const [SelectedImage, setSelectedImage] = useState(null);
  const [ImageUploaded, setImageUploaded] = useState(0);
  const [btnDisable, setbtnDisable] = useState(false);
  const [isOpen, setOpen] = useState(false);
  const [AttachmentID, setAttachmentID] = useState('');
  const [viewImage, setviewImage] = useState('/static/images/avatar/1.jpg');
  const [responseUpload, UploadData] = UseFetchData();
  const [response, fetchData] = UseFetchData();

  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  const handleUploadClick = event => {
    try {
      var file = event.target.files[0];
      var FName = file.name.split('.')[0];
      var FileExtension = file.name.split('.')[1];
      var Base64 = null;
      const reader = new FileReader();
      var url = reader.readAsDataURL(file);
      reader.onloadend = function (e) {
        setSelectedImage([reader.result]);
        Base64 = [reader.result];
        setMainState("uploaded")
        if (MainState === "uploaded") {
          setImageUploaded(1);
          //setSelectedImage(event.target.files[0])
        }
        onUploadFile(FName, Base64, FileExtension);
      };
    }
    catch (error) {
      console.log(error);
    }
  };

  const onUploadFile = (FName, Base64, FileExtension) => {
    debugger;
    var bodyFormData = new FormData();
    bodyFormData.append("Type", "Profile");
    bodyFormData.append("ImageName", FName);
    bodyFormData.append("AttachmentID", "");
    bodyFormData.append("ImageExtension", FileExtension);
    bodyFormData.append("Group", "Profile");
    bodyFormData.append("ImageDescription", "User Profile");
    bodyFormData.append("UserID", sessionStorage.UID);
    bodyFormData.append("ImageString", Base64[0].split(',')[1]);
    UploadData("User/Attachment", bodyFormData);
  };

  const PopulateData = () => {
    var bodyFormData = new FormData();
    bodyFormData.append("UserID", sessionStorage.UID);
    fetchData("User/GetByID", bodyFormData);
  };

  useEffect(() => {
    debugger;
    if (responseUpload.Response === true) {
      setviewImage(responseUpload.Message);
    }
    else if (responseUpload.Response === true) {
      alert('Failed.')
    }
  }, [responseUpload]);

  useEffect(() => {
    debugger;
    if (response !== undefined && response.length > 0) {
      setviewImage(response[0].FilePath);
    }
  }, [response]);

  useEffect(() => {
    PopulateData();
    if (sessionStorage.FilePath !== null) {
      setviewImage(sessionStorage.FilePath);
    }
  }, []);

  const classes = useStyles();

  return (
    <div>
      <IconButton style={{ marginTop: -13 }} edge="start" color="primary" onClick={handleClick}>
        <StyledBadge
          overlap="circular"
          anchorOrigin={{
            vertical: 'bottom',
            horizontal: 'right',
          }}
          variant="dot"
        >
          <Avatar
            alt={props.UserName}
            src={viewImage}
          />
        </StyledBadge>
      </IconButton>
      <StyledMenu
        id="customized-menu"
        anchorEl={anchorEl}
        keepMounted
        open={Boolean(anchorEl)}
        onClose={handleClose}
      //style={{width:900}}
      >
        <StyledMenuItem style={{ width: 300 }} >
          <Grid spacing={0} container style={{ textAlign: 'center' }} >
            {/* <div style={{width:'100%'}}>
              <Grid item xs={12} style={{display:'flex',float:'right'}}>
                <Tooltip title="Change Password">
                  <IconButton onClick={() => {setOpen(true);}}>
                    <HttpsIcon />
                  </IconButton>
                </Tooltip>
              </Grid>
            </div> */}
            <Grid item xs={12}>
              {/* <IconButton edge="start" color="primary" > */}
              {/* <input
                accept="image/*"
                style={{ display: 'none' }}
                id="contained-button-image"
                multiple
                type="file"
                disabled={btnDisable}
                onChange={handleUploadClick}
              /> */}
              <label htmlFor="contained-button-image">
                <Badge
                  overlap="circular"
                  anchorOrigin={{
                    vertical: 'bottom',
                    horizontal: 'right',
                  }}
                  badgeContent={<SmallAvatar style={{ width: 30, height: 30, backgroundColor: 'white' }} src={Camera} />}
                >
                  <Avatar alt={props.UserName} src={viewImage} className={classes.large} />
                </Badge>
              </label>
              {/* </IconButton> */}
            </Grid>
            <Grid item xs={12}>
              <h4>{props.UserName}</h4>
            </Grid>
            <Grid item xs={12} container direction="row">
              {/* <Grid item xs={6}>
                <BiztranControls.Button
                  text="Reset Password"
                  //startIcon={<HttpsIcon />}
                  onClick={() => { setOpen(true); }}
                />
              </Grid> */}
              <Grid item xs={12}>
                <BiztranControls.Button text="Sign Out" className={classes.rightText} backcolor={Global.COLOR.Secondary} onClick={() => { props.handleLogOut(); }} />


                {/* <BiztranControls.Button
                  text="Sign Out"
                  
                  startIcon={<ExitToAppIcon />}
                  onClick={() => {props.handleLogOut();}}
                /> */}
              </Grid>
            </Grid>
          </Grid>
        </StyledMenuItem>
      </StyledMenu>
      {/* <Modal_Frame
        title="Change Password"
        maxWidth="xs"
        isOpen={isOpen}
        setOpen={setOpen}
      >
        <ChangePassword
          UserID={sessionStorage.UID}
          //Password={sessionStorage.Password}
          setOpen={setOpen}
        />
      </Modal_Frame> */}
    </div>
  );
}
