import React from 'react';
import {Formik, Form} from 'formik';
import axios from 'axios';
import SearchIcon from '@material-ui/icons/Search';
import AddCircleOutlineIcon from '@material-ui/icons/AddCircleOutline';
import BiztranControls from '../../BiztranControls/BiztranControls';
import ModalFrame from '../../BiztranControls/Modal_Frame';
import UserCreate from './User_Create';
import global from './../Global';
  
let UserID = '';

export default class User_Master extends React.Component {
    constructor(props) {
        super(props);
        this.PopulateData = this.PopulateData.bind(this);
        this.openModal = this.openModal.bind(this);
        this.openAddModal = this.openAddModal.bind(this);
        this.state ={
          rows:[], columns:[], isPopOpen:false
        };
    }

    openModal(row){
        UserID = row;
        this.setState({isPopOpen:true});
    }

    openAddModal(){
        UserID = "";
        this.setState({isPopOpen:true});
    }

    handleClose = ()=>{
        this.setState({isPopOpen:false});
    };

    PopulateData(){
        var searchString = document.getElementById("username").value;
        var bodyFormData = new FormData();
        bodyFormData.append('UserID','');
        bodyFormData.append('UserTypeID','');
        bodyFormData.append('IsActive','');
        bodyFormData.append('SearchString', searchString);
        axios({
            url: global.BASE_URL+"User/UserList",
            method: 'post',
            headers: {'Content-Type': 'multipart/form-data' },
            data: bodyFormData
        })
        .then((response) => {
            this.setState({rows:response.data});
        })
        .catch((response) => {
            console.log(response);
        });
    }

    componentDidMount(){
        this.PopulateData();
        this.state.columns = [
            { field: 'id', headerName: '.', renderCell: (params) => (<strong> <a href="javascript:;" onClick={() => { this.openModal(params.id); }} >Edit</a> </strong> ), headerClassName: 'super-app-theme--header', headerAlign: 'center', width: 80 },
            { field: 'user_code', headerName: 'User Code', headerClassName: 'super-app-theme--header', headerAlign: 'center', width: 200 },
            { field: 'user_name', headerName: 'User name', headerClassName: 'super-app-theme--header', headerAlign: 'center', width: 210 },
            { field: 'user_type', headerName: 'User Type', headerClassName: 'super-app-theme--header', headerAlign: 'center', width: 200 },
            { field: 'created_by', headerName: 'Created By', headerClassName: 'super-app-theme--header', headerAlign: 'center', width: 200 },
            { field: 'created_on', headerName: 'Created On', headerClassName: 'super-app-theme--header', headerAlign: 'center', width: 160 },
            { field: 'is_active', headerName: 'IsActive', headerClassName: 'super-app-theme--header', headerAlign: 'center', width: 180 },
        ];
    }

    render(){
        return(
            <div>
                <Formik>
                    {formik => {
                        return <Form>
                            <BiztranControls.Input style={{marginTop:16}} label="User Name" name="UserName" id="username" />
                            <BiztranControls.Button backcolor={global.COLOR.Secondary} text="POPULATE" startIcon={<SearchIcon/>}  onClick={() => { this.PopulateData(); }} />
                            <BiztranControls.Button backcolor={global.COLOR.Secondary} text="ADD NEW" startIcon={<AddCircleOutlineIcon/>} onClick={() => { this.openAddModal(); }}/>
                            <ModalFrame title="User Details" maxWidth="sm" isOpen={this.state.isPopOpen} onClose={this.handleClose}>
                                <UserCreate UserID={UserID} />
                            </ModalFrame> 
                            <BiztranControls.DataGrid rows={this.state.rows} columns={this.state.columns} rowHeight={25} />
                        </Form>
                    }}
                </Formik>
            </div>
        );
    }
}