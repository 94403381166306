import React from 'react';
import {Field, ErrorMessage} from 'formik';
import TextError from './TextError';

function Select(props){
    const {label, name, options, ...rest} = props
    return(
        <div>
            <label htmlFor={name} style={{display:'block', width: '100%'}}>{label}</label>
            <Field as='select' id={name} name={name} style={{display:'block', width: '100%', height:'30px', borderRadius:'3px', border:'1px solid #777'}} 
            {...rest} >
               {/*  {options.map(option => {
                    return (
                        <option key={option.value} value={option.value}>
                            {option.key}
                        </option>
                    )
                })} */}
                <option></option>
                {options}
            </Field>
            <ErrorMessage name={name} component={TextError} />
        </div>
    )
}

export default Select
