import React, {useState} from 'react';
import {Formik, Form} from 'formik';
import axios from 'axios';
import { Redirect } from 'react-router-dom';
import SearchIcon from '@material-ui/icons/Search';
import AddCircleOutlineIcon from '@material-ui/icons/AddCircleOutline';
import BiztranControls from '../../BiztranControls/BiztranControls';
import global from './../Global';

let SurveyTempID='';

class Survey_Temp_Master extends React.Component {
    constructor(props){
        super(props);
        this.PopulateData = this.PopulateData.bind(this);
        this.openModal = this.openModal.bind(this);
        this.openAddModal = this.openAddModal.bind(this);
        this.state={
            rows:[], columns:[], AddNew:''
        }
    }

    openModal(row){
        SurveyTempID = row;
        this.setState({AddNew:'/surveytemp/surveytempcreate'});
    }

    openAddModal(){
        SurveyTempID = "";
        this.setState({AddNew:'/surveytemp/surveytempcreate'});
    }

    PopulateData(){
        try {
            var TempName = document.getElementById("tempname").value;
            var bodyFormData = new FormData();
            bodyFormData.append('TempName',TempName);
            axios({
                url: global.BASE_URL+"SurveyAPI/SurveyTempDetails",
                method: 'post',
                headers: {'Content-Type': 'multipart/form-data' },
                data: bodyFormData
            })
            .then((response) => {
                if(response.data.Response === false){
                    this.setState({rows:[]});
                }
                else{
                    this.setState({rows:response.data});
                }
            })
            .catch((response) => {
                console.log(response);
            });
        } catch (error) {
            console.log(error);
        }
    }

    componentDidMount(){
        this.PopulateData();
        this.state.columns = [
            { field: 'id', headerName: '.', renderCell: (params) => (<strong> <a href="javascript:;" onClick={() => { this.openModal(params.id); }} >Select</a> </strong> ), headerClassName: 'super-app-theme--header', headerAlign: 'center', width: 70 },
            { field: 'tempname', headerName: 'Survey Template Name', headerClassName: 'super-app-theme--header', headerAlign: 'center', width: 250 },
            { field: 'template_type', headerName: 'Template Type', headerClassName: 'super-app-theme--header', headerAlign: 'center', width: 205 },
            { field: 'surveytype', headerName: 'Survey Type', headerClassName: 'super-app-theme--header', headerAlign: 'center', width: 250 },
            { field: 'description', headerName: 'Description', headerClassName: 'super-app-theme--header', headerAlign: 'center', width: 250 },
            { field: 'data_capture_mode', headerName: 'Date Capture Mode', headerClassName: 'super-app-theme--header', headerAlign: 'center', width: 205 },
            { field: 'frequency_in_secs', headerName: 'Frequency In Secs', headerClassName: 'super-app-theme--header', headerAlign: 'center', width: 205 },
            { field: 'accuracy_in_cm', headerName: 'Accuracy In Cm', headerClassName: 'super-app-theme--header', headerAlign: 'center', width: 205 },
        ];
    }

    render(){
        const {AddNew} = this.state;
        if (AddNew) return (
            <Redirect to={{ pathname: AddNew, state: { SurveyTempID: SurveyTempID } }} />
        );
        return(
            <div>
                <Formik>
                    {formik => {
                        return <Form>
                            <BiztranControls.Input style={{marginTop:16}} label="Template Name" name="TemplateName" id="tempname" />
                            <BiztranControls.Button text="Search" backcolor={global.COLOR.Secondary} startIcon={<SearchIcon/>}  onClick={() => { this.PopulateData(); }} />
                            <BiztranControls.Button text="ADD NEW" backcolor={global.COLOR.Secondary} startIcon={<AddCircleOutlineIcon/>} onClick={() => { this.openAddModal(); }} />                       
                            <BiztranControls.DataGrid rows={this.state.rows} columns={this.state.columns} rowHeight={25} checkboxSelection={false} />
                        </Form>
                    }}
                </Formik>
            </div>
        )
    }
}

export default Survey_Temp_Master;
