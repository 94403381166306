import React, { useState } from 'react'
import { MuiPickersUtilsProvider, KeyboardDatePicker } from "@material-ui/pickers";
import {makeStyles} from '@material-ui/core';
import DateFnsUtils from "@date-io/date-fns";

const useStyles = makeStyles(theme => ({
    datePik: {
        width: '285px',
        margin: 5
    }
}))

export default function DatePicker() {
    const classes = useStyles(); 
    const [selectedDate, handleDateChange] = useState(new Date());

    return (
        <MuiPickersUtilsProvider utils={DateFnsUtils}>
            <KeyboardDatePicker inputVariant="outlined"
                clearable
                value={selectedDate}
                placeholder="10/10/2018"
                className={classes.datePik}
                onChange={date => handleDateChange(date)}
                minDate={new Date()}
                format="dd/MMM/yyyy"
            />
        </MuiPickersUtilsProvider>
    )
}
