import React from 'react';
import {Field, ErrorMessage} from 'formik';
import TextError from './TextError';

function RadioButton(props) {
    const {label, name, options, ...rest} = props
    return(
        <div>
            <label>{label}</label> <br/>
            <Field name={name} {...rest} >
                {
                    ({ field }) => {
                        return options.map(option => {
                            return(
                                <React.Fragment key={option.key}>
                                    <input 
                                        type='radio' 
                                        id={option.value} 
                                        {...field} 
                                        value={option.value} 
                                        checked={field.value === option.value}
                                        style={{marginLeft:"5px"}} 
                                    />
                                    <label htmlFor={option.value} style={{marginLeft:"5px"}}>{option.key}</label>
                                </React.Fragment>
                            )
                        })
                    }
                }
            </Field>
            <ErrorMessage name={name} component={TextError} />
        </div>
    )
}

export default RadioButton
