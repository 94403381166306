import React from 'react';
import {Field, ErrorMessage} from 'formik';
import TextError from './TextError';

function Textarea(props) {
    const {label, name, ...rest} = props
    return(
        <div>
            <label htmlFor={name} style={{display:'block', width: '100%'}}>{label}</label>
            <Field as='textarea' id={name} name={name} style={{display:'block', width: '100%', height:'45px', padding: '5px', borderRadius:'3px', border:'1px solid black'}} {...rest} />
            <ErrorMessage name={name} component={TextError} />
        </div>
    )
}

export default Textarea
