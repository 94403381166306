import React from 'react';
import TextField from '@material-ui/core/TextField';
import Autocomp from '@material-ui/lab/Autocomplete';
import { makeStyles } from '@material-ui/core/styles';

const useStyles = makeStyles((theme) => ({
  label: {
    "&$focusedLabel": {
      color: "black"
    }
  },
  focusedLabel: {},
  underline: {
    "&:after": {
      borderBottom: `2px solid black`
    }
  },
    root: {
      "& .MuiInputLabel-outlined:not(.MuiInputLabel-shrink)": {
        // Default transform is "translate(14px, 20px) scale(1)""
        // This lines up the label with the initial cursor position in the input
        // after changing its padding-left.
        transform: "translate(16px, 10px) scale(1);"
      },
       height: "10px",
       marginTop:-16,
       marginBottom:36,
    },
    inputRoot: {
      color: "black",
      height: 34,
      fontSize: 16,
      // This matches the specificity of the default styles at https://github.com/mui-org/material-ui/blob/v4.11.3/packages/material-ui-lab/src/Autocomplete/Autocomplete.js#L90
      '[class*="MuiOutlinedInput-root"] .MuiAutocomplete-input:first-child': {
        // Default left padding is 6px
        paddingLeft: 0
      },
      "& .MuiOutlinedInput-notchedOutline": {
        borderColor: "black"
      },
      "&:hover .MuiOutlinedInput-notchedOutline": {
        borderColor: "black"
      },
      "&.Mui-focused .MuiOutlinedInput-notchedOutline": {
        borderColor: "black"
      },
      "& .makeStyles-input-26": {
        height: "0px !important",
      },
      "& input#free-solo-2-demo": {
        padding: "0px"
      }
    }
  }));

export default function Autocomplete(props) {
    const { name, label,  options, onChange, id, value } = props;
    const classes = useStyles();
    
  return (
    <div>
      <Autocomp
        freeSolo
        id="free-solo-2-demo"
        disableClearable
        classes={classes}
        openOnFocus={false}
        options={options}
        name={name}
        getOptionLabel={(option) => option.user_name}
        onChange={onChange}
        value={value}
        renderInput={(params) => (
          <TextField
            {...params}
            label={label}
            margin="normal"
            variant="outlined"
            //name={name}
            id={id}
            InputLabelProps={{
              shrink: true,
              classes: {
                root: classes.label,
                focused: classes.focusedLabel
              }
            }}
            InputProps={{
              ...params.InputProps,
              type: 'search',
              classes: {
                root: classes.underline
              }
            }}
          />
        )}
      />
    </div>
  );
}
