import React from 'react';
import { Dialog, DialogTitle, DialogContent, Typography, Button } from '@material-ui/core';
import Grid from '@material-ui/core/Grid';
import { createMuiTheme, ThemeProvider } from '@material-ui/core/styles';
import { DataGrid } from '@material-ui/data-grid';
import { withStyles } from '@material-ui/styles';
import SaveIcon from '@material-ui/icons/Save';
import CloseIcon from '@material-ui/icons/Close';
import { Formik, Form } from 'formik';
import * as Yup from 'yup';
import global from '../Global';
import BiztranContorls_01 from '../../BiztranControls/BiztranControls';
import BiztranControl from '../../BiztranControls_01/BiztranControls';
import axios from 'axios';
import AddNetworkMultiUsers from "./Add_Network_Multi_Users";
import { red } from '@material-ui/core/colors';

let ProjectID = "", NetworkID = "";

const theme = createMuiTheme({
    typography: {
        fontSize: 13,
    },
});

const styles = theme => ({
    root: {
        '& .super-app-theme--header': {
            backgroundColor: global.COLOR.Secondary,
            color: 'white',
            fontSize: 16,
        },
    },
});

const NetworkArchOptions = [
    { key: 'Centralized', value: 'Centralized' },
    { key: 'Decentralised', value: 'Decentralised' },
    { key: 'Point to Point', value: 'Point to Point' }
]

const NetworkTopologyOptions = [
    { key: 'Linear', value: 'Linear' },
    { key: 'Ring', value: 'Ring' },
    { key: 'Ring only till Primary Splitter', value: 'Ring only till Primary Splitter' }
]

const SplittingLevelOptions = [
    { key: 'Level 1', value: 'Level 1' },
    { key: 'Level 2', value: 'Level 2' }
]

const validationSchema = Yup.object({
    ProjectName: Yup.string().required('Required!'),
    NetworkName: Yup.string().required('Required!'),
    NetworkArch: Yup.string().required('Required!'),
    NetworkTopology: Yup.string().required('Required!'),
    SplittingLevel: Yup.string().required('Required!'),
    TotalHomeConnect: Yup.string().required('Required!')
})

const onSubmit = values => {
    //console.log(values)
    try {
        var UserID = localStorage.getItem("UserID");
        var bodyFormData = new FormData();
        bodyFormData.append('NetworkID', NetworkID);
        bodyFormData.append('ProjectName', values.ProjectName);
        bodyFormData.append('NetworkName', values.NetworkName);
        bodyFormData.append('NetworkArch', values.NetworkArch);
        bodyFormData.append('NetworkTopology', values.NetworkTopology);
        bodyFormData.append('SplittingLevel', values.SplittingLevel);
        bodyFormData.append('TotalHomeConnect', values.TotalHomeConnect);
        bodyFormData.append('TotalDpCount', values.TotalDpCount);
        bodyFormData.append('L1CompCount', values.L1CompCount);
        bodyFormData.append('L2CompCount', values.L2CompCount);
        bodyFormData.append('AverageAttempt', values.AverageAttempt);
        bodyFormData.append('ApproxDistMatrixAPIHitCount', values.DMApiHitCount);
        bodyFormData.append('ApproxDirectAPIHitCount', values.ApiHitCount);
        bodyFormData.append('TotalDirectAPICost', values.TotalApiCost);
        bodyFormData.append('ApproxAPICostINR', values.ApiCostINR);
        bodyFormData.append('UserID', UserID);
        axios({
            url: global.BASE_URL + "STLCNPTNetwork/SaveFTTH",
            method: 'post',
            headers: { 'Content-Type': 'multipart/form-data' },
            data: bodyFormData
        })
            .then((response) => {
                if (response.data.Response === true) {
                    alert("Saved Successfully");
                }
                else {
                    alert("Save Failed");
                }
            })
            .catch((response) => {
                console.log(response);
            });
    }
    catch (error) {
        console.log(error);
    }
}



const columns = [
    { field: 'user_type', headerName: 'User Type', headerClassName: 'super-app-theme--header', headerAlign: 'center', width: 450 },
    { field: 'user_name', headerName: 'User Name', headerClassName: 'super-app-theme--header', headerAlign: 'center', width: 440 },
];

class Create_Network_FTTH extends React.Component {

    constructor(props) {
        super(props);
        this.handleOpenModal = this.handleOpenModal.bind(this);
        this.handleCloseModal = this.handleCloseModal.bind(this);
        this.calculateHitCount = this.calculateHitCount.bind(this);
        this.Approve = this.Approve.bind(this);
        this.state = {
            openModal: null, rows: [], UserDisplay: 'none', ProjectName: '', NetworkName: '', NetworkArch: '', NetworkTopology: '', SplittingLevel: '', TotalHomeConnect: '', ZoneData: []
        };
    }

    handleOpenModal = () => {
        this.setState({ openModal: true });
    };

    handleCloseModal = () => {
        this.setState({ openModal: false });
        this.PopulateUserData();
    };

    calculateHitCount = (e) => {

        this.setState({
            DMApiHitCount: '',
            DMApiCostUSD1: '',
            DMApiCostUSD2: '',
            DMApiCostUSD3: '',
            DMApiCostUSD4: '',
            DMApiCostUSD5: '',
            DMApiCostUSD6: '',
            ApiCostUSD0: '',
            ApiCostUSD1: '',
            ApiCostUSD2: '',
            ApiCostUSD3: '',
            ApiCostUSDI: '',
            ApiCostINR: '',
        });
        var TotalDpCount = 0;
        var L1CompCount = 0;
        var L2CompCount = 0;
        var AverageAttempt = 0;
        var BufferMargin = 0;
        if (this.state.AverageAttempt !== "" && this.state.AverageAttempt !== undefined) {
            AverageAttempt = this.state.AverageAttempt;
        }
        if (this.state.TotalDpCount !== "" && this.state.TotalDpCount !== undefined) {
            TotalDpCount = this.state.TotalDpCount;
        }
        if (this.state.L1CompCount !== "" && this.state.L1CompCount !== undefined) {
            L1CompCount = this.state.L1CompCount;
        }
        if (this.state.L2CompCount !== "" && this.state.L2CompCount !== undefined) {
            L2CompCount = this.state.L2CompCount;
        }
        if (this.state.BufferMargin !== "" && this.state.BufferMargin !== undefined) {
            BufferMargin = this.state.BufferMargin;
        }

        if (e !== '' && e !== undefined) {
            var targetname = e.target.name;
            var targetvalue = e.target.value;
            if (targetvalue !== "") {
                if (targetname === "TotalDpCount") {
                    this.setState({ TotalDpCount: targetvalue });
                    TotalDpCount = targetvalue
                }
                if (targetname === "L1CompCount") {
                    this.setState({ L1CompCount: targetvalue });
                    L1CompCount = targetvalue
                }
                if (targetname === "L2CompCount") {
                    this.setState({ L2CompCount: targetvalue });
                    L2CompCount = targetvalue
                }
                if (targetname === "AverageAttempt") {
                    this.setState({ AverageAttempt: targetvalue });
                    AverageAttempt = targetvalue
                }
                if (targetname === "BufferMargin") {
                    this.setState({ BufferMargin: targetvalue });
                    BufferMargin = targetvalue
                }
            } else {
                if (targetname === "TotalDpCount") {
                    this.setState({ TotalDpCount: '' })
                    TotalDpCount = 0;
                }
                if (targetname === "L1CompCount") {
                    this.setState({ L1CompCount: '' })
                    L1CompCount = 0;
                }
                if (targetname === "L2CompCount") {
                    this.setState({ L2CompCount: '' })
                    L2CompCount = 0;
                }
                if (targetname === "AverageAttempt") {
                    this.setState({ AverageAttempt: '' })
                    AverageAttempt = 0;
                }
                if (targetname === "BufferMargin") {
                    this.setState({ BufferMargin: '' })
                    BufferMargin = 0;
                }
            }
        }
        else {
            TotalDpCount = this.state.TotalDpCount;
            L1CompCount = this.state.L1CompCount;
            L2CompCount = this.state.L2CompCount;
            AverageAttempt = this.state.AverageAttempt;
            BufferMargin = this.state.BufferMargin;
        }
        var DistMatrixApiCost1 = 0;
        var DistMatrixApiCost2 = 0;
        var DistMatrixApiCost3 = 0;
        var DistMatrixApiCost4 = 0;
        var DistMatrixApiCost5 = 0;
        var DistMatrixApiCost6 = 0;
        var TotalCompCount = 0;
        var DistMatrixApiCostUSD = 0;
        var TotalApiCostUSD = 0;
        var DirApiCostUSD0 = 0;
        var DirApiCostUSD1 = 0;
        var DirApiCostUSD2 = 0;
        var DirApiCostUSD3 = 0;
        var AverageAcessNodes = 0;
        var ApxDistMatrixApiHit = 0;
        var TotalDistMatrixApiCost = 0;
        var ApiCostInUSD = 0;
        var TotalCount = 0;
        var ApxDirApiHitCount = 0;
        var TotalDirApiCost = 0;
        var DirApiCostUSD = 0;
        var ApxApiCostUSDI = 0;
        var ApxApiCostINR = 0;
        var MaxMonApiCostInUSD = 0;
        var MaxMonApiCostInINR = 0;
        var cal1 = 0; var cal2 = 0; var cal3 = 0; var cal4 = 0;

        var Margin = BufferMargin / 100;
        var cal1 = L1CompCount * L1CompCount;
        if (L2CompCount == 0 && L1CompCount == 0) {
            cal2 = 0;
        } else {
            cal2 = parseInt(L2CompCount) / parseInt(L1CompCount);
        }
        var cal3 = parseInt(cal2) * parseInt(cal2);
        var cal4 = parseInt(cal1) + parseInt(cal3);
        //ApxDistMatrixApiHit = cal4*AverageAttempt;
        ApxDistMatrixApiHit = ((cal1) + ((cal2) * (cal2))) * AverageAttempt;
        //ApxDistMatrixApiHit = ((L1CompCount*L1CompCount)+((L2CompCount/L1CompCount)*(L2CompCount/L1CompCount)))*AverageAttempt;
        this.setState({ DMApiHitCount: parseInt(ApxDistMatrixApiHit) })
        if (ApxDistMatrixApiHit < 100001) {
            var DistMatrixApiCost1 = Math.round((ApxDistMatrixApiHit * 0.005), 0);
            this.setState({ DMApiCostUSD1: DistMatrixApiCost1 });
        } else {
            var DistMatrixApiCost1 = Math.round((100000 * 0.005), 0);
            this.setState({ DMApiCostUSD1: DistMatrixApiCost1 });
        }
        if (ApxDistMatrixApiHit > 100000 && ApxDistMatrixApiHit <= 500000) {
            var DistMatrixApiCost2 = Math.round((ApxDistMatrixApiHit - 100000) * 0.004, 0);
            this.setState({ DMApiCostUSD2: DistMatrixApiCost2 });
        } else if (ApxDistMatrixApiHit > 500000) {
            var DistMatrixApiCost2 = Math.round((400000 * 0.004), 0);
            this.setState({ DMApiCostUSD2: DistMatrixApiCost2 });
        }
        if (ApxDistMatrixApiHit > 500000 && ApxDistMatrixApiHit <= 1000000) {
            var DistMatrixApiCost3 = Math.round((ApxDistMatrixApiHit - 500000) * 0.003, 0);
            this.setState({ DMApiCostUSD3: DistMatrixApiCost3 });
        } else if (ApxDistMatrixApiHit > 1000000) {
            var DistMatrixApiCost3 = Math.round((1000000 * 0.003), 0);
            this.setState({ DMApiCostUSD3: DistMatrixApiCost3 });
        }
        if (ApxDistMatrixApiHit > 1000000 && ApxDistMatrixApiHit <= 5000000) {
            var DistMatrixApiCost4 = Math.round(((ApxDistMatrixApiHit - 1000000) * 0.0015), 0);
            this.setState({ DMApiCostUSD4: DistMatrixApiCost4 });
        } else if (ApxDistMatrixApiHit > 5000000) {
            var DistMatrixApiCost4 = Math.round(5000000 * 0.0015, 0);
            this.setState({ DMApiCostUSD4: DistMatrixApiCost4 });
        }
        if (ApxDistMatrixApiHit > 5000000 && ApxDistMatrixApiHit <= 10000000) {
            var DistMatrixApiCost5 = Math.round(((ApxDistMatrixApiHit - 10000000) * 0.00038), 0);
            this.setState({ DMApiCostUSD5: DistMatrixApiCost5 });
        } else if (ApxDistMatrixApiHit > 10000000) {
            var DistMatrixApiCost5 = Math.round(10000000 * 0.00038, 0);
            this.setState({ DMApiCostUSD5: DistMatrixApiCost5 });
        }
        if (ApxDistMatrixApiHit > 10000000) {
            var DistMatrixApiCost6 = Math.round(((ApxDistMatrixApiHit - 10000000) * 0.00011), 0);
            this.setState({ DMApiCostUSD6: DistMatrixApiCost6 });
        }
        TotalDistMatrixApiCost = (DistMatrixApiCost1 + DistMatrixApiCost2 + DistMatrixApiCost3 + DistMatrixApiCost4 + DistMatrixApiCost5 + DistMatrixApiCost6)
        this.setState({ DMApiCost: TotalDistMatrixApiCost });
        DistMatrixApiCostUSD = Math.round(TotalDistMatrixApiCost + (Margin * TotalDistMatrixApiCost), 0);
        TotalCompCount = parseInt(L1CompCount) + parseInt(L2CompCount);
        ApxDirApiHitCount = TotalCompCount * AverageAttempt;
        this.setState({ ApiHitCount: ApxDirApiHitCount });
        if (ApxDirApiHitCount < 100001) {
            DirApiCostUSD0 = Math.round((ApxDirApiHitCount * 0.005), 0)
            this.setState({ ApiCostUSD0: DirApiCostUSD0 });
        } else {
            DirApiCostUSD0 = Math.round((100000 * 0.005), 0);
            this.setState({ ApiCostUSD0: DirApiCostUSD0 });
        }
        if (ApxDirApiHitCount > 100000 && ApxDirApiHitCount <= 500000) {
            DirApiCostUSD1 = Math.round(((ApxDirApiHitCount - 100000) * 0.004), 0);
            this.setState({ ApiCostUSD1: DirApiCostUSD1 });
        } else if (ApxDirApiHitCount > 500000) {
            DirApiCostUSD1 = Math.round((400000 * 0.004), 0);
            this.setState({ ApiCostUSD1: DirApiCostUSD1 });
        }
        if (ApxDirApiHitCount > 500000 && ApxDirApiHitCount <= 1000000) {
            DirApiCostUSD2 = Math.round(((ApxDirApiHitCount - 500000) * 0.003), 0);
            this.setState({ ApiCostUSD2: DirApiCostUSD2 });
        } else if (ApxDirApiHitCount > 1000000) {
            DirApiCostUSD2 = Math.round((1000000 * 0.003), 0);
            this.setState({ ApiCostUSD2: DirApiCostUSD2 });
        }
        if (ApxDirApiHitCount > 1000000 && ApxDirApiHitCount <= 5000000) {
            DirApiCostUSD3 = Math.round(((ApxDirApiHitCount - 1000000) * 0.0015), 0);
            this.setState({ ApiCostUSD3: DirApiCostUSD3 });
        } else if (ApxDirApiHitCount > 5000000) {
            DirApiCostUSD3 = Math.round((5000000 * 0.0015), 0);
            this.setState({ ApiCostUSD3: DirApiCostUSD3 });
        }
        TotalDirApiCost = DirApiCostUSD0 + DirApiCostUSD1 + DirApiCostUSD2 + DirApiCostUSD3
        this.setState({ TotalApiCost: TotalDirApiCost });
        TotalApiCostUSD = parseInt(TotalDistMatrixApiCost) + parseInt(TotalDirApiCost);
        DirApiCostUSD = Math.round(TotalDirApiCost + (Margin * (TotalDirApiCost)), 0);
        this.setState({ ApiCostUSD: TotalApiCostUSD })
        ApxApiCostUSDI = DistMatrixApiCostUSD + DirApiCostUSD;
        this.setState({ ApiCostUSDI: ApxApiCostUSDI });
        ApxApiCostINR = ApxApiCostUSDI * 76;
        this.setState({ ApiCostINR: ApxApiCostINR });


    };

    Approve() {
        try {
            debugger;
            var UserID = localStorage.getItem("UserID");
            var bodyFormData = new FormData();
            var NetworkID = this.props.NetworkProjectID;
            var UserType = sessionStorage.getItem("UserType");
            if (UserType === "Admin" || UserType === "Super Admin") {
                bodyFormData.append('NetworkID', NetworkID);
                bodyFormData.append('ProjectName', this.state.ProjectName);
                bodyFormData.append('NetworkName', this.state.NetworkName);
                bodyFormData.append('NetworkArch', this.state.NetworkArch);
                bodyFormData.append('NetworkTopology', this.state.NetworkTopology);
                bodyFormData.append('SplittingLevel', this.state.SplittingLevel);
                bodyFormData.append('TotalHomeConnect', this.state.TotalHomeConnect);
                bodyFormData.append('TotalDpCount', this.state.TotalDpCount);
                bodyFormData.append('L1CompCount', this.state.L1CompCount);
                bodyFormData.append('L2CompCount', this.state.L2CompCount);
                bodyFormData.append('AverageAttempt', this.state.AverageAttempt);
                bodyFormData.append('ApproxDistMatrixAPIHitCount', this.state.DMApiHitCount);
                bodyFormData.append('ApproxDirectAPIHitCount', this.state.ApiHitCount);
                bodyFormData.append('TotalDirectAPICost', this.state.TotalApiCost);
                bodyFormData.append('ApproxAPICostINR', this.state.ApiCostINR);
                bodyFormData.append('UserID', UserID);
                bodyFormData.append('ApproveID', UserID);
            }
            else if (UserType == "Planner") {
                bodyFormData.append('NetworkID', NetworkID);
                bodyFormData.append('ProjectName', this.state.ProjectName);
                bodyFormData.append('NetworkName', this.state.NetworkName);
                bodyFormData.append('NetworkArch', this.state.NetworkArch);
                bodyFormData.append('NetworkTopology', this.state.NetworkTopology);
                bodyFormData.append('SplittingLevel', this.state.SplittingLevel);
                bodyFormData.append('TotalHomeConnect', this.state.TotalHomeConnect);
                bodyFormData.append('TotalDpCount', this.state.TotalDpCount);
                bodyFormData.append('L1CompCount', this.state.L1CompCount);
                bodyFormData.append('L2CompCount', this.state.L2CompCount);
                bodyFormData.append('AverageAttempt', this.state.AverageAttempt);
                //bodyFormData.append('ApproxDistMatrixAPIHitCount',this.state.DMApiHitCount);
                //bodyFormData.append('ApproxDirectAPIHitCount',this.state.ApiHitCount);
                //bodyFormData.append('TotalDirectAPICost',this.state.TotalApiCost);
                //bodyFormData.append('ApproxAPICostINR',this.state.ApiCostINR);
                bodyFormData.append('UserID', UserID);
                //bodyFormData.append('ApproveID',UserID);
                bodyFormData.append('ApiRequestID', UserID);
            }
            axios({
                url: global.BASE_URL + "STLCNPTNetwork/SaveFTTH",
                method: 'post',
                headers: { 'Content-Type': 'multipart/form-data' },
                data: bodyFormData
            })
                .then((response) => {
                    if (response.data.Response === true) {
                        alert("Saved Successfully");
                    }
                    else {
                        alert("Save Failed");
                    }
                })
                .catch((response) => {
                    console.log(response);
                });
        }
        catch (error) {
            console.log(error);
        }
    }



    PopulateUserData() {
        try {
            var bodyFormData = new FormData();
            var NetworkID = this.props.NetworkProjectID;
            bodyFormData.append('NetworkID', NetworkID);
            axios({
                url: global.BASE_URL + "STLCNPTNetwork/UserList",
                method: 'post',
                headers: { 'Content-Type': 'multipart/form-data' },
                data: bodyFormData
            })
                .then((response) => {
                    if (response.data.length > 0) {
                        this.setState({ UserDisplay: 'flex' });
                        this.setState({ rows: response.data });
                    }
                    else {
                        this.setState({ UserDisplay: 'none' });
                    }
                })
                .catch((response) => {
                    console.log(response);
                });
        } catch (error) {
            console.log(error);
        }
    }

    GetNetworkDetails() {
        try {
            var bodyFormData = new FormData();
            bodyFormData.append('NetworkID', NetworkID);
            axios({
                url: global.BASE_URL + "STLCNPTNetwork/GetFTTHByID",
                method: 'post',
                headers: { 'Content-Type': 'multipart/form-data' },
                data: bodyFormData
            })
                .then((response) => {
                    if (response.data.FTTHNetworkData[0].max_dp_count !== null) {
                        this.setState({ TotalDpCount: response.data.FTTHNetworkData[0].max_dp_count });
                    }
                    else {
                        this.setState({ TotalDpCount: 0 })
                    }
                    if (response.data.FTTHNetworkData[0].max_level1_comp_count !== null) {
                        this.setState({ L1CompCount: response.data.FTTHNetworkData[0].max_level1_comp_count });
                    }
                    else {
                        this.setState({ L1CompCount: 0 })
                    }
                    if (response.data.FTTHNetworkData[0].max_level2_comp_count !== null) {
                        this.setState({ L2CompCount: response.data.FTTHNetworkData[0].max_level2_comp_count });
                    }
                    else {
                        this.setState({ L2CompCount: 0 });
                    }
                    this.setState({
                        NetworkName: response.data.FTTHNetworkData[0].network_name,
                        NetworkArch: response.data.FTTHNetworkData[0].network_architecture,
                        NetworkTopology: response.data.FTTHNetworkData[0].network_topology,
                        SplittingLevel: response.data.FTTHNetworkData[0].splitting_level,
                        TotalHomeConnect: response.data.FTTHNetworkData[0].total_home_connect,
                    });
                    this.calculateHitCount('');
                })
                .catch((response) => {
                    console.log(response);
                });
        } catch (error) {
            console.log(error);
        }
    }

    NetworkArchdata() {
        let NetworkArchCombo = NetworkArchOptions.map((data) =>
            <option
                key={data.key}
                value={data.value}
            >
                {data.key}
            </option>
        );
        return NetworkArchCombo;
    }

    NetworkTopologydata() {
        let NetworkTopologyCombo = NetworkTopologyOptions.map((data) =>
            <option
                key={data.key}
                value={data.value}
            >
                {data.key}
            </option>
        );
        return NetworkTopologyCombo;
    }

    SplittingLeveldata() {
        let SplittingLevelCombo = SplittingLevelOptions.map((data) =>
            <option
                key={data.key}
                value={data.value}
            >
                {data.key}
            </option>
        );
        return SplittingLevelCombo;
    }

    componentDidMount() {
        this.setState({ AverageAttempt: 3 });
        this.setState({ BufferMargin: 10 });
        if (this.props.NetworkProjectID != "") {
            ProjectID = this.props.IBDProjectID;
            NetworkID = this.props.NetworkProjectID
            this.PopulateUserData();
            this.GetNetworkDetails();
        }
        else {
            ProjectID = "";
        }
        var UserType = localStorage.getItem("UserType");
        if (UserType == "Planner") {
            this.setState({ btnLabel: "Request Approval" });
        }
        else if (UserType == "Admin") {
            this.setState({ btnLabel: "Approve" });
        }
        else if (UserType == "Super Admin") {
            this.setState({ btnLabel: "Approve" });
        }
    }

    render() {
        const { classes } = this.props;
        let NetworkArchCombo = this.NetworkArchdata();
        let NetworkTopologyCombo = this.NetworkTopologydata();
        let SplittingLevelCombo = this.SplittingLeveldata();
        return (
            <div>
                <Formik initialValues={{
                    ProjectName: this.props.ProjectName, NetworkName: this.state.NetworkName, NetworkArch: this.state.NetworkArch, NetworkTopology: this.state.NetworkTopology,
                    SplittingLevel: this.state.SplittingLevel, TotalHomeConnect: this.state.TotalHomeConnect, TotalDpCount: this.state.TotalDpCount, L1CompCount: this.state.L1CompCount,
                    L2CompCount: this.state.L2CompCount, AverageAttempt: this.state.AverageAttempt, DMApiHitCount: this.state.DMApiHitCount, DMApiCostUSD1: this.state.DMApiCostUSD1,
                    DMApiCostUSD2: this.state.DMApiCostUSD2, DMApiCostUSD3: this.state.DMApiCostUSD3, DMApiCostUSD4: this.state.DMApiCostUSD4, DMApiCostUSD5: this.state.DMApiCostUSD5,
                    DMApiCostUSD6: this.state.DMApiCostUSD6, DMApiCost: this.state.DMApiCost, ApiHitCount: this.state.ApiHitCount, ApiCostUSD0: this.state.ApiCostUSD0, ApiCostUSD1: this.state.ApiCostUSD1,
                    ApiCostUSD2: this.state.ApiCostUSD2, ApiCostUSD3: this.state.ApiCostUSD3, TotalApiCost: this.state.TotalApiCost, ApiCostUSD: this.state.ApiCostUSD, ApiCostUSDI: this.state.ApiCostUSDI,
                    ApiCostINR: this.state.ApiCostINR, BufferMargin: this.state.BufferMargin, btnLabel: this.state.btnLabel
                }}
                    validationSchema={validationSchema} onSubmit={onSubmit} enableReinitialize>
                    {formik => {
                        return <Form>
                            <Grid container spacing={1}>
                                <Grid item xs={4}>
                                    <BiztranControl control='input' type='text' label='Network Name' name='NetworkName' style={{ height: '30px', width: '100%' }} />
                                </Grid>
                                <Grid item xs={4}>
                                    <BiztranControl control='input' type='text' label='Project Name' name='ProjectName' style={{ height: '30px', width: '100%' }} />
                                </Grid>
                                <Grid item xs={4}>
                                    <BiztranControl control='select' label='Network Architecture' name='NetworkArch' options={NetworkArchCombo} style={{ height: '30px', width: '100%' }} />
                                </Grid>
                                <Grid item xs={4}>
                                    <BiztranControl control='select' label='Network Topology' name='NetworkTopology' options={NetworkTopologyCombo} style={{ height: '30px', width: '100%' }} />
                                </Grid>
                                <Grid item xs={4}>
                                    <BiztranControl control='select' label='Splitting Level' name='SplittingLevel' options={SplittingLevelCombo} style={{ height: '30px', width: '100%' }} />
                                </Grid>
                                <Grid item xs={4}>
                                    <BiztranControl control='input' type='text' label='Total Home Connect' name='TotalHomeConnect' style={{ height: '30px', width: '100%' }} />
                                </Grid>
                                <Grid item xs={4}>
                                    <BiztranControl control='input' type='numeric' label='Total Dp Count' name='TotalDpCount' style={{ height: '30px', width: '100%' }} onChange={(e) => { this.calculateHitCount(e); }} />
                                </Grid>
                                <Grid item xs={4}>
                                    <BiztranControl control='input' type='numeric' label='Max Level1 Component Count' name='L1CompCount' style={{ height: '30px', width: '100%' }} onChange={(e) => { this.calculateHitCount(e); }} />
                                </Grid>
                                <Grid item xs={4}>
                                    <BiztranControl control='input' type='numeric' label='Max Level2 Component Count' name='L2CompCount' style={{ height: '30px', width: '100%' }} onChange={(e) => { this.calculateHitCount(e); }} />
                                </Grid>
                                <Grid item xs={4}>
                                    <BiztranControl control='input' type='numeric' label='Average Attempt' name='AverageAttempt' style={{ height: '30px', width: '100%' }} onChange={(e) => { this.calculateHitCount(e); }} />
                                </Grid>
                                <Grid item xs={4}>
                                    <BiztranControl control='input' type='numeric' label='Buffer Margin %' name='BufferMargin' style={{ height: '30px', width: '100%' }} onChange={(e) => { this.calculateHitCount(e); }} />
                                </Grid>
                            </Grid>
                            <Grid container spacing={2}>
                                <Grid item xs={8}>
                                    <label style={{ fontSize: 18, width: '100%', marginTop: 10, backgroundColor: "lightblue" }}>Distance Matrix API Hit Count </label>
                                </Grid>
                                <Grid item xs={4}>
                                    <BiztranControl control='input' disabled={true} type='numeric' name='DMApiHitCount' style={{ height: '28px', width: '100%' }} />
                                </Grid>
                            </Grid>
                            <Grid container spacing={2}>
                                <Grid item xs={8}>
                                    <label style={{ fontSize: 15, marginTop: 5, width: '100%' }}>Distance Matrix API Cost in USD (HC-0-100000)</label>
                                </Grid>
                                <Grid item xs={4}>
                                    <BiztranControl control='input' disabled={true} type='text' name='DMApiCostUSD1' style={{ height: '28px', width: '100%', marginTop: -15 }} />
                                </Grid>
                            </Grid>
                            <Grid container spacing={2}>
                                <Grid item xs={8}>
                                    <label style={{ fontSize: 15, marginTop: 5, width: '100%' }}>Distance Matrix API Cost in USD (HC-100001-500000)</label>
                                </Grid>
                                <Grid item xs={4}>
                                    <BiztranControl control='input' disabled={true} type='text' name='DMApiCostUSD2' style={{ height: '28px', width: '100%', marginTop: -15 }} />
                                </Grid>
                            </Grid>
                            <Grid container spacing={2}>
                                <Grid item xs={8}>
                                    <label style={{ fontSize: 15, marginTop: 5, width: '100%' }}>Distance Matrix API Cost in USD (HC-500001-1000000)</label>
                                </Grid>
                                <Grid item xs={4}>
                                    <BiztranControl control='input' disabled={true} type='text' name='DMApiCostUSD3' style={{ height: '28px', width: '100%', marginTop: -15 }} />
                                </Grid>
                            </Grid>
                            <Grid container spacing={2}>
                                <Grid item xs={8}>
                                    <label style={{ fontSize: 15, marginTop: 5, width: '100%' }}>Distance Matrix API Cost in USD (HC-1000001-5000000)</label>
                                </Grid>
                                <Grid item xs={4}>
                                    <BiztranControl control='input' disabled={true} type='text' name='DMApiCostUSD4' style={{ height: '28px', width: '100%', marginTop: -15 }} />
                                </Grid>
                            </Grid>
                            <Grid container spacing={2}>
                                <Grid item xs={8}>
                                    <label style={{ fontSize: 15, marginTop: 5, width: '100%' }}>Distance Matrix API Cost in USD (HC-5000001-10000000)</label>
                                </Grid>
                                <Grid item xs={4}>
                                    <BiztranControl control='input' disabled={true} type='text' name='DMApiCostUSD5' style={{ height: '28px', width: '100%', marginTop: -15 }} />
                                </Grid>
                            </Grid>
                            <Grid container spacing={2}>
                                <Grid item xs={8}>
                                    <label style={{ fontSize: 15, marginTop: 5, width: '100%' }}>Distance Matrix API Cost in USD (HC-10000000+)</label>
                                </Grid>
                                <Grid item xs={4}>
                                    <BiztranControl control='input' disabled={true} type='text' name='DMApiCostUSD6' style={{ height: '28px', width: '100%', marginTop: -15 }} />
                                </Grid>
                            </Grid>
                            <Grid container spacing={2}>
                                <Grid item xs={8}>
                                    <label style={{ fontSize: 18, width: '100%', marginTop: 10, backgroundColor: "lightblue" }}>Distance Matrix API Cost </label>
                                </Grid>
                                <Grid item xs={4}>
                                    <BiztranControl control='input' disabled={true} type='text' name='DMApiCost' style={{ height: '28px', width: '100%' }} />
                                </Grid>
                            </Grid>
                            <Grid container spacing={2}>
                                <Grid item xs={8}>
                                    <label style={{ fontSize: 18, width: '100%', marginTop: 10, backgroundColor: "lightblue" }}>Approx Directions API Hit Count </label>
                                </Grid>
                                <Grid item xs={4}>
                                    <BiztranControl control='input' disabled={true} type='text' name='ApiHitCount' style={{ height: '28px', width: '100%' }} />
                                </Grid>
                            </Grid>
                            <Grid container spacing={2}>
                                <Grid item xs={8}>
                                    <label style={{ fontSize: 15, marginTop: 0, width: '100%' }}>Directions API Cost in USD (HC-0-100000)</label>
                                </Grid>
                                <Grid item xs={4}>
                                    <BiztranControl control='input' disabled={true} type='text' name='ApiCostUSD0' style={{ height: '28px', width: '100%', marginTop: -15 }} />
                                </Grid>
                            </Grid>
                            <Grid container spacing={2}>
                                <Grid item xs={8}>
                                    <label style={{ fontSize: 15, width: '100%' }}>Directions API Cost in USD (HC-100001-500000)</label>
                                </Grid>
                                <Grid item xs={4}>
                                    <BiztranControl control='input' disabled={true} type='text' name='ApiCostUSD1' style={{ height: '28px', width: '100%' }} />
                                </Grid>
                            </Grid>
                            <Grid container spacing={2}>
                                <Grid item xs={8}>
                                    <label style={{ fontSize: 15, width: '100%' }}>Directions API Cost in USD (HC-500001-1000000)</label>
                                </Grid>
                                <Grid item xs={4}>
                                    <BiztranControl control='input' disabled={true} type='text' name='ApiCostUSD2' style={{ height: '28px', width: '100%' }} />
                                </Grid>
                            </Grid>
                            <Grid container spacing={2}>
                                <Grid item xs={8}>
                                    <label style={{ fontSize: 15, width: '100%' }}>Directions API Cost in USD (HC-1000001-5000000)</label>
                                </Grid>
                                <Grid item xs={4}>
                                    <BiztranControl control='input' disabled={true} type='text' name='ApiCostUSD3' style={{ height: '28px', marginTop: -15, width: '100%' }} />
                                </Grid>
                            </Grid>
                            <Grid container spacing={2}>
                                <Grid item xs={8}>
                                    <label style={{ fontSize: 18, width: '100%', marginTop: 10, backgroundColor: "lightblue" }}>Total Directions API Cost</label>
                                </Grid>
                                <Grid item xs={4}>
                                    <BiztranControl control='input' disabled={true} type='text' name='TotalApiCost' style={{ height: '28px', width: '100%' }} />
                                </Grid>
                            </Grid>
                            <Grid container spacing={2}>
                                <Grid item xs={8}>
                                    <label style={{ fontSize: 15, width: '100%' }}>Approx API Cost in USD (individual Plan) </label>
                                </Grid>
                                <Grid item xs={4}>
                                    <BiztranControl control='input' disabled={true} type='text' name='ApiCostUSD' style={{ height: '28px', marginTop: -15, width: '100%' }} />
                                </Grid>
                            </Grid>
                            <Grid container spacing={2}>
                                <Grid item xs={8}>
                                    <label style={{ fontSize: 18, marginTop: 6, width: '100%', backgroundColor: "lightblue" }}>API Cost in USD (after {this.state.BufferMargin}% Buffer)</label>
                                </Grid>
                                <Grid item xs={4}>
                                    <BiztranControl control='input' disabled={true} type='text' name='ApiCostUSDI' style={{ height: '28px', marginTop: -15, width: '100%' }} />
                                </Grid>
                            </Grid>
                            <Grid container spacing={2}>
                                <Grid item xs={8}>
                                    <label style={{ fontSize: 18, marginTop: 3, width: '100%', backgroundColor: "lightblue" }}>Approx API Cost in INR @76 Rup/USD (Individual Plan)</label>
                                </Grid>
                                <Grid item xs={4}>
                                    <BiztranControl control='input' disabled={true} type='text' name='ApiCostINR' style={{ height: '28px', marginTop: -25, width: '100%' }} />
                                </Grid>
                            </Grid>
                            <Grid>
                                <Grid item xs={12}>
                                    <label style={{ fontSize: 18, width: '100%', color: "red" }}>NOTE: This calculation is approximate and it is based on maximum values</label>
                                </Grid>
                            </Grid>
                            <BiztranContorls_01.Button type="submit" style={{ color: 'white', backgroundColor: global.COLOR.Secondary, marginTop: '10px' }} startIcon={<SaveIcon />}
                                text="Save"
                            />
                            <BiztranContorls_01.Button text={this.state.btnLabel} backcolor={global.COLOR.Secondary} onClick={() => { this.Approve(); }} />

                            <Grid item xs={12}>
                                <a href="javascript:;" onClick={this.handleOpenModal} >Add Users</a>
                                <div style={{ width: '100%', marginTop: 3, display: this.state.UserDisplay }} className={classes.root} >
                                    <ThemeProvider theme={theme}>
                                        <DataGrid
                                            autoHeight
                                            rows={this.state.rows}
                                            columns={columns}
                                            hideFooterPagination={true}
                                            headerHeight={34}
                                            rowHeight={22}
                                        />
                                    </ThemeProvider>
                                </div>
                            </Grid>
                            <Dialog open={this.state.openModal} maxWidth="sm" fullWidth >
                                <DialogTitle style={{ backgroundColor: global.COLOR.Secondary, height: 50, paddingRight: '0px' }}>
                                    <div style={{ display: 'flex' }}>
                                        <Typography variant="h4" component="div" style={{ flexGrow: 1, color: 'white', fontSize: 27 }}>
                                            Users Details
                                        </Typography>
                                        <Button
                                            style={{ color: 'white', paddingTop: 1 }}
                                            onClick={this.handleCloseModal}>
                                            <CloseIcon />
                                        </Button>
                                    </div>
                                </DialogTitle>
                                <DialogContent dividers>
                                    <AddNetworkMultiUsers ProjectIBDID={this.state.ProjectIBDID} NetworkID={this.props.NetworkProjectID} Group="FTTH" />
                                </DialogContent>
                            </Dialog>
                        </Form>
                    }
                    }
                </Formik>
            </div>
        );
    }
}

export default withStyles(styles)(Create_Network_FTTH);
