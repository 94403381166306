import React from 'react'
import { FormControl, InputLabel, Select as MuiSelect, MenuItem, FormHelperText, makeStyles } from '@material-ui/core';
import OutlinedInput from "@material-ui/core/OutlinedInput";

const useStyles = makeStyles(theme => ({
    select: {
        width:'205px',
        height:'34px',
        marginLeft:'10px',
        fontSize: 16,
    },
    /* div: {
        marginTop: theme.spacing(2),
    } */
}))

export default function Select(props) {

    const { name, label, value, error=null, onChange, options, ...reset } = props;
    const classes = useStyles();   
    const inputLabel = React.useRef(null);
    const [labelWidth, setLabelWidth] = React.useState(0);
    React.useEffect(() => {
        setLabelWidth(inputLabel.current.offsetWidth);
    }, []);

    return (
        <FormControl variant="outlined" size="small" {...reset}
        {...(error && {error:true})}>
            <InputLabel style={{marginLeft:'9px'}} ref={inputLabel} shrink={ true }>{label}</InputLabel>
            <MuiSelect
                label={label}
                name={name}  
                className={classes.select}
                size="small"
                value={value}
                input={
                    <OutlinedInput
                    notched
                    labelWidth={labelWidth}/>
                }
                onChange={onChange}>  
                <MenuItem value="">None</MenuItem>
                {
                    options.map(
                        item => (<MenuItem key={item.id} value={item.id}>{item.title}</MenuItem>)
                    )
                }
            </MuiSelect>
            {error && <FormHelperText>{error}</FormHelperText>}
        </FormControl>
    )
}
