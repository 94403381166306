import React from 'react';
import {Field, ErrorMessage} from 'formik';
import TextError from './TextError';

function Input(props){
    const {label, id, name, disabled, ...rest} = props
    var backgroundColor = '';
    if(disabled === true){
        backgroundColor = '#DCDCDC'
    }
    else{
        backgroundColor = 'white'
    }
    return(
        <div>
            <label htmlFor={name} style={{display:'block', width: '100%'}}>{label}</label>
            <Field 
                name={name} 
                disabled={disabled}
                autoComplete="off"
                style={{display:'block', width: '100%', height:'30px', padding: '7px',backgroundColor:backgroundColor, borderRadius:'3px', border:'1px solid black'}} 
                {...rest}
            />
            <ErrorMessage name={name} component={TextError} />
        </div>
    )
}

export default Input
