import React from 'react';
import {Field, ErrorMessage} from 'formik';
import TextError from './TextError';

function Checkbox(props){
    const {label, id, name, onChange, ...rest} = props
    return(
        <div>
            <label htmlFor={name} style={{display:'block', width: '100%'}}>{label}</label>
            <Field 
                type="checkbox" 
                name={name} 
                onChange={onChange}
                style={{display:'block', width: '100%', padding:'10px', marginBottom:'20px', borderRadius:'3px', border:'1px solid black'}} 
                {...rest}
            />
            <ErrorMessage name={name} component={TextError} />
        </div>
    )
}

export default Checkbox
