import React from 'react';
import Home from '../components/Home';
import User_Master from "../components/Masters/User_Master";
import Project_Master from "../components/Masters/Transport_Project_Master";
import FTTH_Project_Master from "../components/Masters/FTTH_Project_Master";
import Survey_Home from "../components/Survey/Survey_Home";
//import SurveyQueTemp_Home from "../components/SurveyQueTemplate/SurveyQueTemp_Home";
import Transport_Product_Master from "../components/Product Master/Transport_Product_Master";
import FTTH_Product_Master from "../components/Product Master/FTTH_Product_Master";
import Error_Page from "../components/Error_Page";
import Create_FTTH_Project from "../components/Masters/Create_FTTH_Project";
import Create_Transport_Project from "../components/Masters/Create_Transport_Project";
import Survey_Temp_Master from "../components/Survey Template/Survey_Temp_Master";
import Create_Survey_Temp from "../components/Survey Template/Create_Survey_Temp";
import ToolUsageSummary from '../components/Reports/Tool_Usage_Summary';
import APIConsumption from '../components/Reports/API_Consumption';
import Session from "react-session-api";
import Login from '../components/Login';
import { UserID } from '../components/Global';

class MDI_Frame extends React.Component {

    constructor(props) {
        super(props);
        this.state = {
        };
    }

    render() {

        // console.log(this.props);
        // console.log(Session.get("UserID"));
        // if(Session.get("UserID")!== null)
        if (Session.get("UserID") !== undefined &&
            Session.get("UserID") !== "" &&
            Session.get("UserID") !== null) {
            if (this.props.name === "Home") {
                return (
                    <div>
                        <div style={{ backgroundColor: "#A9A9A9", color: "white", height: "43px", paddingLeft: '16px', fontSize: "30px", marginTop: "6.5%" }}>
                            Home
                        </div>
                        <Home />
                    </div>
                );
            }
            else if (this.props.name === "User") {
                return (
                    <div>
                        <div style={{ backgroundColor: "#A9A9A9", color: "white", height: "43px", paddingLeft: '16px', fontSize: "30px", marginTop: "6.5%" }}>
                            User Master
                        </div>
                        <User_Master />
                    </div>
                );
            }
            else if (this.props.name === "Transport Product") {
                return (
                    <div>
                        <div style={{ backgroundColor: "#A9A9A9", color: "white", height: "43px", paddingLeft: '16px', fontSize: "30px", marginTop: "6.5%" }}>
                            Transport Product Master
                        </div>
                        <Transport_Product_Master />
                    </div>
                );
            }
            else if (this.props.name === "FTTH Product") {
                return (
                    <div>
                        <div style={{ backgroundColor: "#A9A9A9", color: "white", height: "43px", paddingLeft: '16px', fontSize: "30px", marginTop: "6.5%" }}>
                            FTTH Product Master
                        </div>
                        <FTTH_Product_Master />
                    </div>
                );
            }
            else if (this.props.name === "Project") {
                return (
                    <div>
                        <div style={{ backgroundColor: "#A9A9A9", color: "white", height: "43px", paddingLeft: '16px', fontSize: "30px", marginTop: "6.5%" }}>
                            Transport Project Master
                        </div>
                        <Project_Master />
                    </div>
                );
            }
            else if (this.props.name === "FTTH Project") {
                return (
                    <div>
                        <div style={{ backgroundColor: "#A9A9A9", color: "white", height: "43px", paddingLeft: '16px', fontSize: "30px", marginTop: "6.5%" }}>
                            FTTH Project Master
                        </div>
                        <FTTH_Project_Master />
                    </div>
                );
            }
            else if (this.props.name === "Survey") {
                return (
                    <div>
                        <div style={{ backgroundColor: "#A9A9A9", color: "white", height: "43px", paddingLeft: '16px', fontSize: "30px", marginTop: "6.5%" }}>
                            Survey
                        </div>
                        <Survey_Home />
                    </div>
                );
            }
            else if (this.props.name === "SurveyQueTemp") {
                return (
                    <div>
                        <div style={{ backgroundColor: "#A9A9A9", color: "white", height: "43px", paddingLeft: '16px', fontSize: "30px", marginTop: "6.5%" }}>
                            Survey Template
                        </div>
                        <Survey_Temp_Master />
                    </div>
                );
            }
            else if (this.props.name === "Error Page") {
                return (
                    <div>
                        <div style={{ backgroundColor: "#A9A9A9", color: "white", height: "43px", paddingLeft: '16px', fontSize: "30px", marginTop: "6.5%" }}>
                            Error Tracker
                        </div>
                        <Error_Page />
                    </div>
                );
            }
            else if (this.props.name === "FTTH Create Project") {
                return (
                    <div>
                        <div style={{ backgroundColor: "#A9A9A9", color: "white", height: "43px", paddingLeft: '16px', fontSize: "30px", marginTop: "6.5%" }}>
                            FTTH Project Details
                        </div>
                        <Create_FTTH_Project />
                    </div>
                );
            }
            else if (this.props.name === "Transport Create Project") {
                return (
                    <div>
                        <div style={{ backgroundColor: "#A9A9A9", color: "white", height: "43px", paddingLeft: '16px', fontSize: "30px", marginTop: "6.5%" }}>
                            Transport Project Details
                        </div>
                        <Create_Transport_Project />
                    </div>
                );
            }
            else if (this.props.name === "Survey Template Create") {
                return (
                    <div>
                        <div style={{ backgroundColor: "#A9A9A9", color: "white", height: "43px", paddingLeft: '16px', fontSize: "30px", marginTop: "6.5%" }}>
                            Survey Template Details
                        </div>
                        <Create_Survey_Temp />
                    </div>
                );
            }
            else if (this.props.name === "ToolUsageSummary") {
                return (
                    <div>
                        <div style={{ backgroundColor: "#A9A9A9", color: "white", height: "43px", paddingLeft: '16px', fontSize: "30px", marginTop: "6.5%" }}>
                            Tool Usage Summary
                        </div>
                        <ToolUsageSummary />
                    </div>
                );
            }
            else if (this.props.name === "APIConsumption") {
                return (
                    <div>
                        <div style={{ backgroundColor: "#A9A9A9", color: "white", height: "43px", paddingLeft: '16px', fontSize: "30px", marginTop: "6.5%" }}>
                            API Consumption
                        </div>
                        <APIConsumption />
                    </div>
                );
            }

        }
        else {
            return (
                <div>
                    <div style={{ backgroundColor: "#A9A9A9", color: "white", height: "43px", paddingLeft: '16px', fontSize: "30px", marginTop: "6.5%" }}>
                        API Consumption
                    </div>
                    <Login />
                </div>
            );
        }
    }

}

export default MDI_Frame;