import React, { useState } from 'react';
import { Formik, Form } from 'formik';
import axios from 'axios';
import SearchIcon from '@material-ui/icons/Search';
import AddCircleOutlineIcon from '@material-ui/icons/AddCircleOutline';
import BiztranControls from '../../BiztranControls/BiztranControls';
import Modal_Frame from '../../BiztranControls/Modal_Frame';
//import Survey_Create from './Survey_Create';
import SurveyCreate from "./SurveyCreate";
import global from '../Global';

let SurveyID = '', btnText = "", disableLink = "", disableLinkColor = "", NetworkDisplay = "";

export default class Survey_Home extends React.Component {
    constructor(props) {
        super(props);
        this.PopulateData = this.PopulateData.bind(this);
        this.openModal = this.openModal.bind(this);
        this.openAddModal = this.openAddModal.bind(this);
        this.state = {
            rows: [], columns: [], isPopOpen: false
        };
    }

    openModal(row) {

        SurveyID = row.id;
        btnText = "Update";
        disableLink = "flex";
        disableLinkColor = "#0000FF";
        NetworkDisplay = "flex";
        this.setState({ isPopOpen: true });
    }

    openAddModal() {
        SurveyID = "";
        btnText = "Insert";
        disableLink = "none";
        disableLinkColor = "#777777";
        NetworkDisplay = "none";
        this.setState({ isPopOpen: true });
    }

    handleClose = () => {
        this.setState({ isPopOpen: false });
    }

    PopulateData() {
        var bodyFormData = new FormData();
        var searchString = document.getElementById("surveyname").value;
        bodyFormData.append('SurveyNo', searchString);
        axios({
            url: global.BASE_URL + "SurveyAPI/GetSurveyDetails",
            method: 'post',
            headers: { 'Content-Type': 'multipart/form-data' },
            data: bodyFormData
        })
            .then((response) => {
                this.setState({ rows: response.data });
            })
            .catch((response) => {
                console.log(response);
            });
    }

    componentDidMount() {
        this.PopulateData();
        this.state.columns = [
            { field: 'id', headerName: '.', renderCell: (params) => (<strong> <a href="javascript:;" onClick={() => { this.openModal(params); }} >Edit</a> </strong>), headerClassName: 'super-app-theme--header', headerAlign: 'center', width: 70 },
            { field: 'surveyno', headerName: 'Survey No', headerClassName: 'super-app-theme--header', headerAlign: 'center', width: 160 },
            { field: 'surveydate', headerName: 'Survey Date', headerClassName: 'super-app-theme--header', headerAlign: 'center', width: 160 },
            { field: 'surveytype', headerName: 'Survey Type', headerClassName: 'super-app-theme--header', headerAlign: 'center', width: 270 },
            { field: 'project', headerName: 'Project', headerClassName: 'super-app-theme--header', headerAlign: 'center', width: 200 },
            { field: 'network', headerName: 'Network', headerClassName: 'super-app-theme--header', headerAlign: 'center', width: 230 },
            { field: 'expectedcompletiondate', headerName: 'Expected Completion Date', headerClassName: 'super-app-theme--header', headerAlign: 'center', width: 230 },
        ];
    }

    render() {
        return (
            <div>
                <Formik>
                    {formik => {
                        return <Form>
                            <BiztranControls.Input style={{ marginTop: 16 }} label="Survey Name" name="SurveyName" id="surveyname" />
                            <BiztranControls.Button backcolor={global.COLOR.Secondary} text="POPULATE" startIcon={<SearchIcon />} onClick={() => { this.PopulateData(); }} />
                            <BiztranControls.Button backcolor={global.COLOR.Secondary} text="ADD NEW" startIcon={<AddCircleOutlineIcon />} onClick={() => { this.openAddModal(); }} />
                            <Modal_Frame title="Survey Details" maxWidth="md" isOpen={this.state.isPopOpen} onClose={this.handleClose}>
                                <SurveyCreate SurveyID={SurveyID} handleClose={this.handleClose} PopulateData={this.PopulateData} btnText={btnText} disableLink={disableLink} disableLinkColor={disableLinkColor} NetworkDisplay={NetworkDisplay} />
                            </Modal_Frame>
                            <BiztranControls.DataGrid rows={this.state.rows} columns={this.state.columns} rowHeight={25} checkboxSelection={false} />
                        </Form>
                    }}
                </Formik>
            </div>
        );
    }
}