import React, { useState, useEffect } from 'react';
import { Dialog, DialogTitle, DialogContent, Typography, Button } from '@material-ui/core';
import Grid from '@material-ui/core/Grid';
import { createMuiTheme, ThemeProvider } from '@material-ui/core/styles';
import { withStyles } from '@material-ui/styles';
import CloseIcon from '@material-ui/icons/Close';
import SaveIcon from '@material-ui/icons/Save';
import { Formik, Form } from 'formik';
import * as Yup from 'yup';
import { DataGrid } from '@material-ui/data-grid';
import UseFetchData from "../hooks/UseFetchData";
import BiztranControls from "../biztran_controls/BiztranControls";
import BiztranControlsFormik from "../biztran_controls_formik/BiztranControlsFormik";
import global from '../Global';
//import Survey_Assign from "./Survey_Assign";
//import AddMultipleSurTemp from "./AddMultipleSurTemp";
import SurveyAssign from "./SurveyAssign";
import AddMultiSurveyTemp from "./AddMultiSurveyTemp";
import axios from 'axios';

let ProjectID = "", date = new Date(new Date()).toLocaleDateString([], { year: 'numeric', month: 'long', day: 'numeric' });

const styles = theme => ({
    root: {
        '& .super-app-theme--header': {
            backgroundColor: global.COLOR.Secondary,
            color: 'white',
            fontSize: 16,
        },
    },
});

const SurveyCreate = (props) => {
    const [surveyno, setsurveyno] = useState('');
    //const [SurveyNo,setSurveyNo] = useState('');
    const [ProjectName, setProjectName] = useState('');
    const [NetworkName, setNetworkName] = useState('');
    const [openModal, setopenModal] = useState(false);
    const [openSurTempModal, setopenSurTempModal] = useState(false);
    const [SurveyDate, setSurveyDate] = useState(date);
    const [Network, setNetwork] = useState([]);
    const [Project_ID, setProject_ID] = useState('');
    const [Network_ID, setNetwork_ID] = useState('');
    const [SurveyType, setSurveyType] = useState('');
    const [SurveyStatus, setSurveyStatus] = useState('');
    const [SurveyID, setSurveyID] = useState('');
    const [Project, setProject] = useState([]);
    const [SurTemprows, setSurTemprows] = useState([]);
    const [ExpectedCompletionDate, setExpectedCompletionDate] = useState('');
    const [response, fetchData] = UseFetchData();
    const [responseSurveyDtls, fetchDataSurveyDts] = UseFetchData();
    const [responseTempData, fetchDataTempData] = UseFetchData();

    const theme = createMuiTheme({
        typography: {
            fontSize: 13,
        },
    });

    const validationSchema = Yup.object({
        //surveyno:Yup.string().required('Required!'),
        //ProjectID:Yup.string().required('Required!'),
        //NetworkID:Yup.string().required('Required!'),
        SurveyType: Yup.string().required('Required!'),
        ExpectedCompletionDate: Yup.string().required('Required!')
    });

    const SurTempcolumns = [
        { field: 'template_type', headerName: 'Template Type', headerClassName: 'super-app-theme--header', headerAlign: 'center', width: 205 },
        { field: 'tempname', headerName: 'Template Name', headerClassName: 'super-app-theme--header', headerAlign: 'center', width: 205 },
        { field: 'description', headerName: 'Description', headerClassName: 'super-app-theme--header', headerAlign: 'center', width: 205 },
        { field: 'data_capture_mode', headerName: 'Data Capture Mode', headerClassName: 'super-app-theme--header', headerAlign: 'center', width: 205 },
    ];

    const onSubmit = values => {
        try {
            //GetSurveyNo();
            if (props.btnText === "Update") {
                var bodyFormData = new FormData();
                bodyFormData.append('SurveyID', props.SurveyID);
                bodyFormData.append('SurveyType', values.SurveyType);
                bodyFormData.append('ProjectID', values.ProjectID);
                bodyFormData.append('NetworkID', values.NetworkID);
                bodyFormData.append('ExpectedCompletionDate', values.ExpectedCompletionDate);
                fetchData("SurveyAPI/UpdateSurveyData", bodyFormData);
            }
            else {
                var bodyFormData = new FormData();

                //bodyFormData.append('SurveyNo',surveyno);
                bodyFormData.append('SurveyType', values.SurveyType);
                bodyFormData.append('ProjectID', values.ProjectID);
                bodyFormData.append('NetworkID', values.NetworkID);
                bodyFormData.append('ExpectedCompletionDate', values.ExpectedCompletionDate);
                fetchData("SurveyAPI/InsertSurveyData", bodyFormData);
            }
        }
        catch (error) {
            console.log(error)
        }
    };

    const FillProject = () => {
        axios.post(global.BASE_URL + "SurveyAPI/FillProject").then((response) => {
            setProject(response.data);
        });
    }

    const Projectdata = () => {
        let ProjectCombo = Project.map((data) =>
            <option
                key={data.projectname}
                value={data.projectid}
            >
                {data.projectname}
            </option>
        );
        return ProjectCombo;
    }

    const FillNetwork = () => {
        var bodyFormData = new FormData();
        bodyFormData.append('ProjectID', ProjectID);
        axios({
            url: global.BASE_URL + "SurveyAPI/FillNetwork",
            method: 'post',
            headers: { 'Content-Type': 'multipart/form-data' },
            data: bodyFormData
        })
            .then((response) => {
                setNetwork(response.data);
            })
            .catch((response) => {
                console.log(response);
            });
    }

    const Networkdata = () => {
        let NetworkCombo = Network.map((data) =>
            <option
                key={data.networkname}
                value={data.networkid}
            >
                {data.networkname}
            </option>
        );
        return NetworkCombo;
    }

    const GetSurveyTemplateData = () => {
        var bodyFormData = new FormData();
        bodyFormData.append('SurveyID', props.SurveyID);
        fetchDataTempData("SurveyAPI/MultiSurveyTemplateData", bodyFormData);
    }

    const handleChange = (event) => {
        setProject_ID(event.target.value);
        ProjectID = event.target.value;
        //let index = event.nativeEvent.target.selectedIndex;
        FillNetwork();
    };

    const SurveyTypeData = () => {
        let SurveyType = [
            { key: 'Pre Planning', value: 'Pre Planning' },
            { key: 'After Planning', value: 'After Planning' },
            { key: 'Post Execution', value: 'Post Execution' }
        ]
        let SurveyTypeCombo = SurveyType.map((data) =>
            <option
                key={data.key}
                value={data.value}
            >
                {data.key}
            </option>
        );
        return SurveyTypeCombo;
    }

    const GetSurveyDetails = () => {
        var bodyFormData = new FormData();
        bodyFormData.append('SurveyID', props.SurveyID);
        fetchDataSurveyDts("SurveyAPI/SurveyDetails", bodyFormData);
    }

    /* const GetSurveyNo = () => {
        axios.post(global.BASE_URL+"SurveyAPI/GenerateSurveyNo").then((response) => {
            setsurveyno(response.data);
        });
    } */

    const handleOpenModal = () => {
        setopenModal(true);
    };

    const handleOpenSurTempModal = () => {
        setopenSurTempModal(true);
    };

    const handleCloseModal = () => {
        setopenModal(false);
    };

    const handleCloseSurTempModal = () => {
        setopenSurTempModal(false);
        GetSurveyTemplateData();
    };

    useEffect(() => {

        FillProject();
        if (props.SurveyID !== '') {
            setSurveyID(props.SurveyID);
            GetSurveyDetails();
            GetSurveyTemplateData();
        }
        else {
            setSurveyID('');
        }
    }, []);

    useEffect(() => {

        if (response.length > 0 || response.length === undefined) {
            if (response.Response === true) {
                props.handleClose();
                props.PopulateData();
                alert("Saved Successfully.");
            }
            else {
                alert("Save Failed.");
            }
        }
    }, [response]);

    useEffect(() => {

        if (responseSurveyDtls.length > 0 || responseSurveyDtls.length === undefined) {
            setsurveyno(responseSurveyDtls[0].surveyno);
            setSurveyDate(responseSurveyDtls[0].surveydate);
            setSurveyType(responseSurveyDtls[0].surveytype);
            setProjectName(responseSurveyDtls[0].project);
            setNetworkName(responseSurveyDtls[0].network);
            setExpectedCompletionDate(responseSurveyDtls[0].expectedcompletiondate);
            setProject_ID(responseSurveyDtls[0].project_id);
            setNetwork_ID(responseSurveyDtls[0].network_id);
        }
    }, [responseSurveyDtls]);

    useEffect(() => {

        if (responseTempData.length > 0 || responseTempData.length === undefined) {
            setSurTemprows(responseTempData);
        }
        else {
            setSurTemprows([]);
        }
    }, [responseTempData]);

    const { classes } = props;
    let ProjectCombo = Projectdata();
    let NetworkCombo = Networkdata();
    let SurveyTypeCombo = SurveyTypeData();

    return (
        <div>
            <Formik initialValues={{
                surveyno: surveyno, SurveyDate: SurveyDate, SurveyType: SurveyType,
                ProjectID: Project_ID, NetworkID: Network_ID, ExpectedCompletionDate: ExpectedCompletionDate
            }}
                validationSchema={validationSchema} onSubmit={onSubmit} enableReinitialize>
                {formik => {
                    return <Form>
                        <Grid container spacing={1}>
                            <Grid item xs={12}>
                                <label name="SurveyStatus" value={SurveyStatus} style={{ height: "25px", fontSize: "14px", color: "white", backgroundColor: "red", marginLeft: "94%" }}> Pending</label>
                            </Grid>
                            <Grid item xs={6}>
                                <BiztranControlsFormik control='input' type='text' label='Survey No' name='surveyno' disabled={true} style={{ backgroundColor: '#DCDCDC', height: '30px', width: '100%' }} />
                            </Grid>
                            <Grid item xs={6}>
                                <BiztranControlsFormik control='input' type='text' label='Survey Date' name='SurveyDate' value={date} disabled={true} style={{ backgroundColor: '#DCDCDC', height: '30px', width: '100%' }} />
                            </Grid>
                            <Grid item xs={12}>
                                <BiztranControlsFormik control='select' label='Survey Type' name='SurveyType' options={SurveyTypeCombo} onChange={event => setSurveyType(event.target.value)} style={{ height: '30px', width: '100%' }} />
                            </Grid>
                            <Grid item xs={6}>
                                <BiztranControlsFormik control='select' label='Project' name='ProjectID' options={ProjectCombo} onChange={handleChange} style={{ height: '30px', width: '100%' }} />
                            </Grid>
                            <Grid item xs={6}>
                                <BiztranControlsFormik control='select' label='Network' name='NetworkID' options={NetworkCombo} onChange={event => setNetwork_ID(event.target.value)} style={{ height: '30px', width: '100%' }} />
                            </Grid>
                            <Grid item xs={6}>
                                <BiztranControlsFormik control='input' type='date' label='Expected Completion Date' name='ExpectedCompletionDate' style={{ height: '30px', width: '100%' }} />
                            </Grid>
                            <Grid item xs={6}>
                                <BiztranControlsFormik control='input' type='text' label='Actual Completion Date' name='ActualCompletionDate' disabled={true} style={{ backgroundColor: '#DCDCDC', height: '30px', width: '100%' }} />
                            </Grid>
                            <Grid item xs={12}>
                                <BiztranControls.Button type="submit" style={{ color: 'white', backgroundColor: global.COLOR.Secondary, marginTop: '10px' }} startIcon={<SaveIcon />}
                                    text="Save"
                                />
                            </Grid>
                            <Grid item xs={12}>
                                <a href="javascript:;" style={{ pointerEvents: props.disableLink, color: props.disableLinkColor }} onClick={handleOpenSurTempModal} >{" "}Add Survey Template{" "}</a>
                                <a href="javascript:;" style={{ marginLeft: "65%", pointerEvents: props.disableLink, color: props.disableLinkColor }} onClick={handleOpenModal} >{" "}Assign To Surveyor{" "}</a>
                            </Grid>
                            <Grid item xs={12}>
                                <div style={{ width: '100%', marginTop: 7, display: props.NetworkDisplay }} className={classes.root} >
                                    <ThemeProvider theme={theme}>
                                        <DataGrid
                                            autoHeight
                                            rows={SurTemprows}
                                            columns={SurTempcolumns}
                                            hideFooterPagination={true}
                                            headerHeight={34}
                                            rowHeight={22}
                                        />
                                    </ThemeProvider>
                                </div>
                            </Grid>
                        </Grid>
                        <Dialog open={openModal} maxWidth="sm" fullWidth >
                            <DialogTitle style={{ backgroundColor: global.COLOR.Secondary, height: 50, paddingRight: '0px' }}>
                                <div style={{ display: 'flex' }}>
                                    <Typography variant="h4" component="div" style={{ flexGrow: 1, color: 'white', fontSize: 27 }}>
                                        Assign To Surveyor
                                    </Typography>
                                    <Button
                                        style={{ color: 'white', paddingTop: 1 }}
                                        onClick={handleCloseModal}>
                                        <CloseIcon />
                                    </Button>
                                </div>
                            </DialogTitle>
                            <DialogContent dividers>
                                <SurveyAssign SurveyID={SurveyID} handleCloseModal={handleCloseModal} />
                            </DialogContent>
                        </Dialog>
                        <Dialog open={openSurTempModal} maxWidth="sm" fullWidth >
                            <DialogTitle style={{ backgroundColor: global.COLOR.Secondary, height: 50, paddingRight: '0px' }}>
                                <div style={{ display: 'flex' }}>
                                    <Typography variant="h4" component="div" style={{ flexGrow: 1, color: 'white', fontSize: 27 }}>
                                        Add Survey Template
                                    </Typography>
                                    <Button
                                        style={{ color: 'white', paddingTop: 1 }}
                                        onClick={handleCloseSurTempModal}>
                                        <CloseIcon />
                                    </Button>
                                </div>
                            </DialogTitle>
                            <DialogContent dividers>
                                <AddMultiSurveyTemp SurveyID={SurveyID} SurveyType={SurveyType} GetSurveyTemplateData={GetSurveyTemplateData} handleCloseSurTempModal={handleCloseSurTempModal} />
                            </DialogContent>
                        </Dialog>
                    </Form>
                }
                }
            </Formik>
        </div>
    );
}

export default withStyles(styles)(SurveyCreate);
