import React from 'react';
import {Formik, Form} from 'formik';
import axios from 'axios';
import { Redirect } from 'react-router-dom';
//import AddCircleOutlineIcon from '@material-ui/icons/AddCircleOutline';
import SearchIcon from '@material-ui/icons/Search';
import BiztranControls from '../../BiztranControls/BiztranControls';
import global from '../Global';
  
let FTTHProjectID='';
let Status = '';

const StatusOptions =[
    { id:'Active', title:'Active' },
    { id:'InActive', title:'InActive' }
];

export default class FTTH_Project_Master extends React.Component {
    constructor(props) {
        super(props);
        this.PopulateData = this.PopulateData.bind(this);
        this.openModal = this.openModal.bind(this);
        this.AddNew = this.AddNew.bind(this);
        this.state ={
          rows:[], columns:[], isPopOpen:false, addNew:false, FTTHProjectID:''
        };
    }

    openModal(row){
        FTTHProjectID = row;
        this.setState({ addNew: '/ftthproject/ftthcreateproject',FTTHProjectID:row });
        //this.setState({isPopOpen:true});
    }

    AddNew(){
        FTTHProjectID = '';
        this.setState({ addNew: '/ftthproject/ftthcreateproject',FTTHProjectID:'' });
    }

    handleChangeStatus = (event) => {
        Status = event.target.value
    };

    handleClose = ()=>{
        this.setState({ isPopOpen:false });
    };

    PopulateData(){
        try {
            var searchString = document.getElementById("projectname").value;
            var bodyFormData = new FormData();
            bodyFormData.append('ProjectName', searchString);
            bodyFormData.append('IsActive', Status);
            axios({
                url: global.BASE_URL+"STLCNPTProject/FTTHList",
                method: 'post',
                headers: {'Content-Type': 'multipart/form-data' },
                data: bodyFormData
            })
            .then((response) => {
                this.setState({rows:response.data});
            })
            .catch((response) => {
                console.log(response);
            });
        } catch (error) {
            console.log(error);
        }
    }

    componentDidMount(){
        this.PopulateData();
        this.state.columns = [
            { field: 'id', headerName: '.', renderCell: (params) => (<strong> <a href="javascript:;" onClick={() => { this.openModal(params.id); }} >Edit</a> </strong> ), headerClassName: 'super-app-theme--header', headerAlign: 'center', width: 70 },
            { field: 'ftth_project_name', headerName: 'Project Name', headerClassName: 'super-app-theme--header', headerAlign: 'center', width: 270 },
            { field: 'ftth_project_desc', headerName: 'Project Description', headerClassName: 'super-app-theme--header', headerAlign: 'center', width: 300 },
            { field: 'created_by', headerName: 'Created By', headerClassName: 'super-app-theme--header', headerAlign: 'center', width: 220 },
            { field: 'created_on', headerName: 'Created On', headerClassName: 'super-app-theme--header', headerAlign: 'center', width: 210 },
            { field: 'is_active', headerName: 'IsActive', headerClassName: 'super-app-theme--header', headerAlign: 'center', width: 160 },
        ];
    }

    render(){
        const {addNew} = this.state;
        if (addNew) return (
        <Redirect to={{ pathname: addNew, state: { FTTHProjectID: FTTHProjectID }  }} />
        );
        return(
            <div>
                <Formik >
                    {formik => {
                        return <Form>
                            <BiztranControls.Input style={{marginTop:16}} label="Project Name" name="ProjectName" id="projectname" />
                            <BiztranControls.Select label="Status" name="status" onChange={this.handleChangeStatus} options={StatusOptions} />
                            <BiztranControls.Button backcolor={global.COLOR.Secondary} text="POPULATE" startIcon={<SearchIcon/>}  onClick={() => { this.PopulateData(); }} />
                            {/* <BiztranControls.Button backcolor={global.COLOR.Secondary} text="ADD NEW" startIcon={<AddCircleOutlineIcon/>} onClick={() => { this.AddNew(); }}/>  */}            
                            <BiztranControls.DataGrid rows={this.state.rows} columns={this.state.columns} rowHeight={25} checkboxSelection={false} />
                        </Form>
                    }}
                </Formik>
            </div>
        );
    }
}