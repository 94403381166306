import React from 'react'
import { DataGrid } from '@material-ui/data-grid';
import { makeStyles } from '@material-ui/core/styles';
import global from '../components/Global';

const useStyles = makeStyles((theme) => ({
    root: {
      '& .super-app-theme--header': {
        backgroundColor: global.COLOR.Secondary,
        color: 'white',
      },
    },
  }));

export default function Data_Grid(props) {

    const { rows, columns, rowHeight, ...rest } = props;
    const classes = useStyles();
    return (
      <div style={{ height: 430, width: '100%' }}  className={classes.root} >
        <DataGrid 
          rows={rows} 
          columns={columns} 
          /* autoPageSize */
          headerHeight={34} 
          rowHeight={rowHeight} 
          {...rest} 
        />
      </div>
    )
}
