import React from 'react';
import { BrowserRouter as Router, Switch, Route, Link } from "react-router-dom";
import { useHistory } from "react-router-dom";
import { Container, Drawer, CssBaseline, AppBar, Toolbar, List, Typography, Divider, ListItem, ListItemText, Avatar, Grid } from "@material-ui/core";
import { makeStyles, useTheme } from '@material-ui/core/styles';
import MainLogoSTL from '../components/images/MainLogoSTL.png';
import HomeIcon from "@material-ui/icons/Home";
import MenuIcon from '@material-ui/icons/Menu';
import ListItemIcon from '@material-ui/core/ListItemIcon';
import ErrorOutlineIcon from '@material-ui/icons/ErrorOutline';
import EventNoteIcon from '@material-ui/icons/EventNote';
import ChromeReaderModeRoundedIcon from '@material-ui/icons/ChromeReaderModeRounded';
import IconButton from '@material-ui/core/IconButton';
import ChevronLeftIcon from '@material-ui/icons/ChevronLeft';
import ChevronRightIcon from '@material-ui/icons/ChevronRight';
import AccountBoxIcon from '@material-ui/icons/AccountBox';
import InboxIcon from '@material-ui/icons/MoveToInbox';
import ExpandLess from '@material-ui/icons/ExpandLess';
import ExpandMore from '@material-ui/icons/ExpandMore';
import Collapse from '@material-ui/core/Collapse';
import VideoLabelIcon from '@material-ui/icons/VideoLabel';
import AssignmentSharpIcon from '@material-ui/icons/AssignmentSharp';
import clsx from 'clsx';
import MDI_Frame from './MDI_Frame';
import global from "../components/Global";
import Create_FTTH_Project from './../components/Masters/Create_FTTH_Project';
import SearchIcon from '@material-ui/icons/Search';
import BiztranControls from '../BiztranControls/BiztranControls';
import Session from "react-session-api";
import IdleTimeOutHandler from '../components/IdleTimeOutHandler'

//profilepage integration
import ProfilePage from '../components/ProfilePage'


import { useIdleTimer } from 'react-idle-timer'

const drawerWidth = 240;

const useStyles = makeStyles((theme) => ({
  root: {
    display: 'flex',
  },
  link: {
    textDecoration: 'none',
    color: theme.palette.text.primary,
  },
  Disablelink: {
    textDecoration: 'none',
    color: theme.palette.text.primary,
    display: localStorage.getItem("UserName") === "Biztran" ? 'flex' : 'none'
  },
  appBar: {
    zIndex: theme.zIndex.drawer + 1,
    transition: theme.transitions.create(['width', 'margin'], {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.leavingScreen,
    }),
  },
  appBarShift: {
    marginLeft: drawerWidth,
    width: `calc(100% - ${drawerWidth}px)`,
    transition: theme.transitions.create(['width', 'margin'], {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.enteringScreen,
    }),
  },
  menuButton: {
    marginRight: 36,
  },
  hide: {
    display: 'none',
  },
  drawer: {
    width: drawerWidth,
    flexShrink: 0,
    whiteSpace: 'nowrap',
  },
  drawerOpen: {
    width: drawerWidth,
    transition: theme.transitions.create('width', {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.enteringScreen,
    }),
  },
  drawerClose: {
    transition: theme.transitions.create('width', {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.leavingScreen,
    }),
    overflowX: 'hidden',
    width: theme.spacing(7) + 1,
    [theme.breakpoints.up('sm')]: {
      width: theme.spacing(9) + 1,
    },
  },
  toolbar: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'flex-end',
    padding: theme.spacing(0, 1),
    ...theme.mixins.toolbar,
  },
  rightText: {
    textAlign: "right"
  }
}));

export default function Navbar() {
  const classes = useStyles();
  const theme = useTheme();
  const [open, setOpen] = React.useState(false);
  const [openModal, setOpenModal] = React.useState(false);
  const [openMaster, setMasterOpen] = React.useState(false);
  const [openReport, setOpenReport] = React.useState(false);
  const [openErrorPage, setErrorPageOpen] = React.useState(false);

  const [isActive, setIsActive] = React.useState(true)
  const [isLogout, setLogout] = React.useState(false)

  const handleOnIdle = event => {
    HandleLogout()
  }

  const { getRemainingTime, getLastActiveTime } = useIdleTimer({
    timeout: 1000 * 60 * 5,
    onIdle: handleOnIdle,
    debounce: 500
  })

  const handleDrawerOpen = () => {
    setOpen(true);
  };

  const handleDrawerClose = () => {
    setOpen(false);
    setOpenModal(false);
    setMasterOpen(false);
    setOpenReport(false);
  };

  const handleClick = () => {
    setOpenModal(!openModal);
  };

  const handleMasterClick = () => {
    setMasterOpen(!openMaster);
  };

  const handleReportClick = () => {
    setOpenReport(!openReport);
  };

  const history = useHistory();

  const HandleLogout = () => {
    Session.clear();
    history.push("login")
    // Route to Login Page
  }

  /* const handleErrorPageClick = () => {
    setErrorPageOpen(!openErrorPage);
  }; */

  return (
    <Router>
      {/* <IdleTimeOutHandler
        onActive={() => { setIsActive(true) }}
        onIdle={() => { setIsActive(false) }}
        onLogout={HandleLogout}
      /> */}

      <div className={classes.root}>
        <CssBaseline />
        <AppBar position="fixed" className={clsx(classes.appBar, { [classes.appBarShift]: open, })} style={{ backgroundColor: global.COLOR.Secondary }}>
          <Toolbar>
            <IconButton color="inherit" aria-label="open drawer" onClick={handleDrawerOpen} edge="start" className={clsx(classes.menuButton, { [classes.hide]: open, })} >
              <MenuIcon />
            </IconButton>
            <Grid container justify="space-between">
              <Typography noWrap align="right" style={{ fontSize: 20, marginTop: 20 }}>
                STLCNPT

              </Typography>
              {/* <p>
                {isLogout ? 'Please reload to Login' :
                  isActive ? 'Hello There' : 'Interact to be active'
                }
              </p> */}

              <div
                style={{
                  float: "right",
                  marginRight: "0.1rem",
                  marginTop: "0.5rem",
                }}
              >
                <ProfilePage UserName={Session.get("UserName")} handleLogOut={HandleLogout} />
              </div>
              {/* <Typography noWrap  >
                
                <BiztranControls.Button text="Sign Out" className={classes.rightText} backcolor={"#808080"} startIcon={<SearchIcon />} onClick={HandleLogout} />
              </Typography> */}

            </Grid>


          </Toolbar>

        </AppBar>
        <Drawer variant="permanent" className={clsx(classes.drawer, {
          [classes.drawerOpen]: open, [classes.drawerClose]: !open,
        })}
          classes={{ paper: clsx({ [classes.drawerOpen]: open, [classes.drawerClose]: !open, }), }}>
          <div className={classes.toolbar} style={{ backgroundColor: global.COLOR.Secondary, height: '50px' }} >
            <img src={MainLogoSTL} style={{ height: '50px', width: '130px' }} />
            <IconButton onClick={handleDrawerClose} style={{ color: 'white' }} >
              {theme.direction === 'rtl' ? <ChevronRightIcon style={{ color: 'white' }} /> : <ChevronLeftIcon />}
            </IconButton>
          </div>
          <Divider />
          <List>
            <Link to="/home" className={classes.link}>
              <ListItem button>
                <ListItemIcon>
                  <HomeIcon />
                </ListItemIcon>
                <ListItemText primary={"Home"} />
              </ListItem>
            </Link>
            <Link to="/project" className={classes.link}>
              <ListItem button>
                <ListItemIcon>
                  <EventNoteIcon />
                </ListItemIcon>
                <ListItemText primary={"Project(Transport)"} />
              </ListItem>
            </Link>
            <Link to="/ftthproject" className={classes.link}>
              <ListItem button>
                <ListItemIcon>
                  <EventNoteIcon />
                </ListItemIcon>
                <ListItemText primary={"Project(FTTH)"} />
              </ListItem>
            </Link>
            <ListItem button onClick={handleClick}>
              <ListItemIcon>
                <InboxIcon />
              </ListItemIcon>
              <ListItemText primary="Master" />
              {openModal ? <ExpandLess /> : <ExpandMore />}
            </ListItem>
            <Collapse in={openModal} timeout="auto" unmountOnExit>
              <Link to="/trasportproduct" className={classes.link}>
                <List component="div" disablePadding>
                  <ListItem button className={classes.nested} style={{ marginLeft: "7%" }}>
                    <VideoLabelIcon />
                    <ListItemText primary={"Product(Transport)"} />
                  </ListItem>
                </List>
              </Link>
              <Link to="/ftthproduct" className={classes.link}>
                <List component="div" disablePadding>
                  <ListItem button className={classes.nested} style={{ marginLeft: "7%" }}>
                    <VideoLabelIcon />
                    <ListItemText primary={"Product(FTTH)"} />
                  </ListItem>
                </List>
              </Link>
              <Link to="/usermaster" className={classes.link}>
                <List component="div" disablePadding>
                  <ListItem button className={classes.nested} style={{ marginLeft: "7%" }}>
                    <AccountBoxIcon />
                    <ListItemText primary={"User Master"} />
                  </ListItem>
                </List>
              </Link>
            </Collapse>
            <ListItem button onClick={handleMasterClick}>
              <ListItemIcon>
                <InboxIcon />
              </ListItemIcon>
              <ListItemText primary="Survey" />
              {openMaster ? <ExpandLess /> : <ExpandMore />}
            </ListItem>
            <Collapse in={openMaster} timeout="auto" unmountOnExit>
              <Link to="/survey" className={classes.link}>
                <List component="div" disablePadding>
                  <ListItem button className={classes.nested} style={{ marginLeft: "7%" }}>
                    <AssignmentSharpIcon />
                    <ListItemText primary={"Survey"} />
                  </ListItem>
                </List>
              </Link>
              <Link to="/surveytemp" className={classes.link}>
                <List component="div" disablePadding>
                  <ListItem button className={classes.nested} style={{ marginLeft: "7%" }}>
                    <ChromeReaderModeRoundedIcon />
                    <ListItemText primary={"Survey Template"} />
                  </ListItem>
                </List>
              </Link>
            </Collapse>
          </List>

          <ListItem button onClick={handleReportClick}>
            <ListItemIcon>
              <InboxIcon />
            </ListItemIcon>
            <ListItemText primary="Reports" />
            {openReport ? <ExpandLess /> : <ExpandMore />}
          </ListItem>
          <Collapse in={openReport} timeout="auto" unmountOnExit>
            <Link to="/ToolUsageSummary" className={classes.link}>
              <List component="div" disablePadding>
                <ListItem button className={classes.nested} style={{ marginLeft: "7%" }}>
                  <ChromeReaderModeRoundedIcon />
                  <ListItemText primary={"Tool Usage Summary"} />
                </ListItem>
              </List>
            </Link>
            <Link to="/APIConsumption" className={classes.link}>
              <List component="div" disablePadding>
                <ListItem button className={classes.nested} style={{ marginLeft: "7%" }}>
                  <ChromeReaderModeRoundedIcon />
                  <ListItemText primary={"API Consumption"} />
                </ListItem>
              </List>
            </Link>
          </Collapse>

          <Link to="/errorpage" className={classes.Disablelink} >
            <ListItem button>
              <ListItemIcon>
                <ErrorOutlineIcon />
              </ListItemIcon>
              <ListItemText primary={"Error Tracker"} />
            </ListItem>
          </Link>

        </Drawer>
        <Switch>
          <Route exact path="/home">
            <Container>
              <main className={clsx(classes.content, { [classes.contentShift]: open, })} >
                <Typography gutterBottom>
                  <MDI_Frame name="Home" />
                </Typography>
              </main>
            </Container>
          </Route>
          <Route exact path="/survey">
            <Container>
              <main className={clsx(classes.content, { [classes.contentShift]: open, })} >
                <Typography gutterBottom>
                  <MDI_Frame name="Survey" />
                  {/* <EmployeeCreate/> */}
                </Typography>
              </main>
            </Container>
          </Route>
          <Route exact path="/errorpage">
            <Container>
              <main className={clsx(classes.content, { [classes.contentShift]: open, })} >
                <Typography gutterBottom>
                  <MDI_Frame name="Error Page" />
                  {/* <EmployeeCreate/> */}
                </Typography>
              </main>
            </Container>
          </Route>
          <Route exact path="/usermaster">
            <Container>
              <main className={clsx(classes.content, { [classes.contentShift]: open, })} >
                <Typography gutterBottom>
                  <MDI_Frame name="User" />
                </Typography>
              </main>
            </Container>
          </Route>
          <Route exact path="/ftthproduct">
            <Container>
              <main className={clsx(classes.content, { [classes.contentShift]: open, })} >
                <Typography gutterBottom>
                  <MDI_Frame name="FTTH Product" />
                </Typography>
              </main>
            </Container>
          </Route>
          <Route exact path="/trasportproduct">
            <Container>
              <main className={clsx(classes.content, { [classes.contentShift]: open, })} >
                <Typography gutterBottom>
                  <MDI_Frame name="Transport Product" />
                </Typography>
              </main>
            </Container>
          </Route>
          <Route exact path="/project">
            <Container>
              <main className={clsx(classes.content, { [classes.contentShift]: open, })} >
                <Typography gutterBottom>
                  <MDI_Frame name="Project" />
                </Typography>
              </main>
            </Container>
          </Route>
          <Route exact path="/ftthproject">
            <Container>
              <main className={clsx(classes.content, { [classes.contentShift]: open, })} >
                <Typography gutterBottom>
                  <MDI_Frame name="FTTH Project" />
                </Typography>
              </main>
            </Container>
          </Route>
          <Route exact path="/surveytemp">
            <Container>
              <main className={clsx(classes.content, { [classes.contentShift]: open, })} >
                <Typography gutterBottom>
                  <MDI_Frame name="SurveyQueTemp" />
                </Typography>
              </main>
            </Container>
          </Route>
          <Route exact path="/ftthproject/ftthcreateproject" render={(props) => <Create_FTTH_Project {...props} />}>
            <Container>
              <main className={clsx(classes.content, { [classes.contentShift]: open, })} >
                <Typography gutterBottom>
                  <MDI_Frame name="FTTH Create Project" />
                </Typography>
              </main>
            </Container>
          </Route>
          <Route exact path="/project/trancreateproject">
            <Container>
              <main className={clsx(classes.content, { [classes.contentShift]: open, })} >
                <Typography gutterBottom>
                  <MDI_Frame name="Transport Create Project" />
                </Typography>
              </main>
            </Container>
          </Route>
          <Route exact path="/surveytemp/surveytempcreate">
            <Container>
              <main className={clsx(classes.content, { [classes.contentShift]: open, })} >
                <Typography gutterBottom>
                  <MDI_Frame name="Survey Template Create" />
                </Typography>
              </main>
            </Container>
          </Route>
          <Route exact path="/ToolUsageSummary">
            <Container>
              <main className={clsx(classes.content, { [classes.contentShift]: open, })} >
                <Typography gutterBottom>
                  <MDI_Frame name="ToolUsageSummary" />
                </Typography>
              </main>
            </Container>
          </Route>
          <Route exact path="/APIConsumption">
            <Container>
              <main className={clsx(classes.content, { [classes.contentShift]: open, })} >
                <Typography gutterBottom>
                  <MDI_Frame name="APIConsumption" />
                </Typography>
              </main>
            </Container>
          </Route>
        </Switch>
      </div>
    </Router>
  );
}
