import React from 'react';

function TextError(props) {
    return(
        <div style={{color:'red'}}>
            {props.children}
        </div>
    )
}

export default TextError
