module.exports = {
  BASE_URL: 'http://stl.biztran.in/',
  //BASE_URL: 'http://stlcore.biztran.in/',
  //BASE_URL: 'http://localhost:5454/',
  //BASE_URL: 'http://10.100.120.150:81/',
  UserName: '',
  UserID: "",
  COLOR: {
    Primary: "#1B47C5",
    Secondary: "#13C16B",
  },
}; 